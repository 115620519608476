import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders } from '../../../../../../redux/reducers/utils/financeReducers/financeOrdersReducer';
import { Form, Input, Button, Typography, Flex, InputNumber, DatePicker, Select } from 'antd';
import { setCurrentOrdersPage } from '../../../../../../redux/reducers/utils/clientsReducers/clientsSearchReducer';
import styled from 'styled-components';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { Text } = Typography;
const { Option } = Select;

const FiltersComponent = () => {
    const dispatch = useDispatch();
    const {
        currentOrdersPage,
        totalOrders,
        orderStatusesList,
    } = useSelector((state) => state.financeOrders);
    const [form] = Form.useForm();
    
    const getQuery = (page, orderId, deliveryStart, deliveryEnd, orderStatus, phone, productName, userId, kitchenId) => {
        let filters = '';
        if (orderId) filters += `orderId: ${orderId}, `;
        if (userId) filters += `userId: ${userId}, `;
        if (kitchenId) filters += `kitchenId: ${kitchenId}, `;
        if (deliveryStart) filters += `deliveryStart: "${deliveryStart}", `;
        if (deliveryEnd) filters += `deliveryEnd: "${deliveryEnd}", `;
        if (orderStatus) filters += `orderStatus: "${orderStatus}", `;
        if (phone) filters += `phone: "${phone}", `;
        if (productName) filters += `productName: "${productName}", `;

        return `
            query {
                orderGroups(
                    sortBy: "created",
                    page: ${page},
                    limit: 10,
                    ${filters}
                ) {
                    data {
                        id
                        created
                        deliveryDay
                        finalPrice
                        user {
                            name
                            phone
                        }
                        checks {
                            id
                            url
                            created
                            hashReceipt
                            receipt
                            receiptSystemId
                            checkTypeData {
                                id
                                name
                            }
                            checkPayments {
                                amount
                                created
                                checkId
                                paymentStatus
                                id
                                transactionId
                                updated
                                paySystem {
                                    name
                                }
                                cardTransaction {
                                    amount
                                    bankResponse
                                    comment
                                    created
                                    id
                                    paymentKey
                                }
                                walletTransaction {
                                    amount
                                    comment
                                    created
                                    timeTo
                                    walletFrom
                                    walletTo
                                }
                            }
                            checkItems {
                                orderProduct {
                                    id
                                    name
                                    price
                                    amount
                                    orderProductId
                                    picture {
                                        url
                                    }
                                }
                            }
                        }
                        address {
                            cityData {
                                name
                            }
                            structure
                            street
                            house
                            floor
                            flat
                            entrance
                            location {
                                name
                                prefix
                                id
                            }
                        }
                        orders {
                            comment
                            deliveryTime
                            id
                            kitchen {
                                name
                            }
                            courier {
                                name
                            }
                            orderProduct {
                                id
                                amount
                                name
                                price
                                orderProductId
                                picture {
                                    url
                                }
                            }
                            price
                            orderStatus {
                                id
                                name
                            }
                            orderLogs {
                                created
                                event
                                extra_info
                                id
                                orderId
                            }
                        }
                    }
                    paginator {
                        currentPage
                        total
                    }
                }
                orderStatuses {
                    id
                    name
                }
            }
        `;
    };

    useEffect(() => {
        const query = getQuery(currentOrdersPage);
        dispatch(fetchOrders(query));
    }, [currentOrdersPage, dispatch]);

    const handleSearch = (values) => {
        const { orderId, deliveryStart, deliveryEnd, orderStatus, phone, productName, userId, kitchenId } = values;
        console.log(values);
        const formattedDeliveryStart = deliveryStart ? dayjs(deliveryStart).format('YYYY-MM-DD') : null;
        const formattedDeliveryEnd = deliveryEnd ? dayjs(deliveryEnd).format('YYYY-MM-DD') : null;
        console.log(formattedDeliveryStart, formattedDeliveryEnd);

        const query = getQuery(1, orderId, formattedDeliveryStart, formattedDeliveryEnd, orderStatus, phone, productName, userId, kitchenId);
        dispatch(setCurrentOrdersPage(1));
        dispatch(fetchOrders(query));
    };

    const handleReset = () => {
        form.resetFields();
        const query = getQuery(1);
        dispatch(setCurrentOrdersPage(1));
        dispatch(fetchOrders(query));
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSearch}>
            <Flex gap={16}>
                <StyledFormItem name='orderId' label='ID заказа'>
                    <InputNumber
                        placeholder='Введите ID заказа'
                        min={0}
                        style={{ width: 150 }}
                    />
                </StyledFormItem>

                <StyledFormItem name='userId' label='ID пользователя'>
                    <InputNumber
                        placeholder='Введите ID пользователя'
                        min={0}
                        style={{ width: 150 }}
                    />
                </StyledFormItem>

                <StyledFormItem name='kitchenId' label='ID кухни'>
                    <InputNumber
                        placeholder='Введите ID кухни'
                        min={0}
                        style={{ width: 150 }}
                    />
                </StyledFormItem>
            </Flex>

            <Flex gap={16}>
                <StyledFormItem name='deliveryStart' label='от' style={{flex: 1}}>
                    <DatePicker
                        placeholder='Выберите дату'
                        format={'DD-MM-YYYY'}
                        style={{ width: '100%' }}
                    />
                </StyledFormItem>

                <StyledFormItem name='deliveryEnd' label='до' style={{flex: 1}}>
                    <DatePicker
                        placeholder='Выберите дату'
                        format={'DD-MM-YYYY'}
                        style={{ width: '100%' }}
                    />
                </StyledFormItem>

                <StyledFormItem name='orderStatus' label='Статус заказа'>
                    <Select
                        placeholder='Введите статус заказа'
                    >
                        {orderStatusesList.map((status) => (
                            <Option key={status.id} value={status.id}>{status.name}</Option>
                        ))}
                    </Select>
                </StyledFormItem>
            </Flex>

            <Flex gap={16}>
                <StyledFormItem name='phone' label='Телефон' style={{flex: 1}}>
                    <Input placeholder='Введите номер телефона'/>
                </StyledFormItem>

                <StyledFormItem name='productName' label='Продукт' style={{flex: 1}}>
                    <Input placeholder='Введите название продукта'/>
                </StyledFormItem>
            </Flex>

            <Flex style={{marginTop: '20px'}} justify='space-between' gap={16}>
                <Text type='secondary'>Найдено заказов: {new Intl.NumberFormat().format(totalOrders)}</Text>
                <Flex gap={16}>
                    <Button type='primary' htmlType='submit'>Поиск</Button>
                    <Button htmlType='reset' onClick={handleReset}>Сбросить</Button>
                </Flex>
            </Flex>
        </Form>
    );
};

export default FiltersComponent;

const StyledFormItem = styled(Form.Item)`
    margin: 5px
`