import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import apiInstance from '../../../../api';
import { selectCommonState, setServerTime } from '../commonReducer';

export const getClientTokens = createAsyncThunk(
  'clientsSearch/getClientTokens',
  async (data, { getState, rejectWithValue }) => {
    const api = '/user/getTokens?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const clientDisable = createAsyncThunk(
  'clientsSearch/clientDisable',
  async (data, { getState, rejectWithValue }) => {
    const api = '/user/disable?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const disableTokenByLike = createAsyncThunk(
  'clientsSearch/disableTokenByLike',
  async (data, { getState, rejectWithValue }) => {
    const api = '/user/disableTokenByLike?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

const initialState = {
  //Search
  clientsGetRequestStatus: null,
  totalClients: 0,
  clients: [],
  currentPage: 1,

  //Detail
  clientProfileGetRequestStatus: null,
  clientProfile: null,

  clientOrdersStatisticsGetRequestStatus: null,
  clientOrdersStatistics: null,

  clientOrdersGetRequestStatus: null,
  totalOrders: 0,
  clientOrders: [],
  currentOrdersPage: 1,

  clientAddressesGetRequestStatus: null,
  clientAddresses: null,
  makeAddressActiveRequestStatus: null,

  clientDevicesGetRequestStatus: null,
  clientDevices: [],

  pushNotificationGetRequestStatus: null,
  pushNotification: [],

  messagesGetRequestStatus: null,
  messages: [],

  getClientTokenRequestStatus: null,
  clientTokenInfo: null,
  clientDisableRequestStatus: null,
};

const clientsSearchSlice = createSlice({
  name: 'clientsSearch',
  initialState,
  extraReducers: (builder) => {
    builder
    // getClientTokens
    .addCase(getClientTokens.pending, (state) => {
      state.getClientTokenRequestStatus = 'pending';
    })
    .addCase(getClientTokens.fulfilled, (state, action) => {
      state.getClientTokenRequestStatus = 'fulfilled';
      const data = action.payload;
      state.clientTokenInfo = data.tokens.map((token) => ({
        ...token,
        statusName: data.tokenStatuses[token.status] || 'Неизвестный статус'
      }));
    })
    .addCase(getClientTokens.rejected, (state) => {
      state.getClientTokenRequestStatus = 'rejected';
    })

    // clientDisable
    .addCase(clientDisable.pending, (state) => {
      state.clientDisableRequestStatus = 'pending';
    })
    .addCase(clientDisable.fulfilled, (state) => {
      state.clientDisableRequestStatus = 'fulfilled';
    })
    .addCase(clientDisable.rejected, (state) => {
      state.clientDisableRequestStatus = 'rejected';
    })
    .addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    //Search
    sendRequestClientsGet: (state) => { state.clientsGetRequestStatus = 'pending'; },
    sendRequestClientsGetSuccess: (state) => { state.clientsGetRequestStatus = 'resolved'; },
    sendRequestClientsGetError: (state) => { state.clientsGetRequestStatus = 'rejected'; },
    clearClientsGetStatus: (state) => { state.clientsGetRequestStatus = null; },
    setClients: (state, action) => {
      const data = action.payload;

      state.totalClients = data.countUsers;
      state.clients = data.search
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    //Detail
    sendRequestClientProfileGet: (state) => { state.clientProfileGetRequestStatus = 'pending'; },
    sendRequestClientProfileGetSuccess: (state) => { state.clientProfileGetRequestStatus = 'resolved'; },
    sendRequestClientProfileGetError: (state) => { state.clientProfileGetRequestStatus = 'rejected'; },
    clearClientProfileGetStatus: (state) => { state.clientProfileGetRequestStatus = null; },
    setClientProfile: (state, action) => {
      state.clientProfile = action.payload;
    },

    sendRequestClientOrdersStatisticsGet: (state) => { state.clientOrdersStatisticsGetRequestStatus = 'pending'; },
    sendRequestClientOrdersStatisticsGetSuccess: (state) => { state.clientOrdersStatisticsGetRequestStatus = 'resolved'; },
    sendRequestClientOrdersStatisticsGetError: (state) => { state.clientOrdersStatisticsGetRequestStatus = 'rejected'; },
    clearClientOrdersStatisticsGetStatus: (state) => { state.clientOrdersStatisticsGetRequestStatus = null; },
    setClientOrdersStatistics: (state, action) => {
      state.clientOrdersStatistics = action.payload;
    },

    sendRequestClientOrdersGet: (state) => { state.clientOrdersGetRequestStatus = 'pending'; },
    sendRequestClientOrdersGetSuccess: (state) => { state.clientOrdersGetRequestStatus = 'resolved'; },
    sendRequestClientOrdersGetError: (state) => { state.clientOrdersGetRequestStatus = 'rejected'; },
    clearClientOrdersGetStatus: (state) => { state.clientOrdersGetRequestStatus = null; },
    setClientOrders: (state, action) => {
      const data = action.payload;

      state.totalOrders = data.countOrders;
      state.clientOrders = data.orsersGroups;
    },
    setCurrentOrdersPage: (state, action) => {
      state.currentOrdersPage = action.payload;
    },
    
    sendRequestClientAddressesGet: (state) => { state.clientAddressesGetRequestStatus = 'pending'; },
    sendRequestClientAddressesGetSuccess: (state) => { state.clientAddressesGetRequestStatus = 'resolved'; },
    sendRequestClientAddressesGetError: (state) => { state.clientAddressesGetRequestStatus = 'rejected'; },
    clearClientAddressesGetStatus: (state) => { state.clientAddressesGetRequestStatus = null; },
    setClientAddresses: (state, action) => {
      state.clientAddresses = action.payload;
    },
    sendRequestMakeAddressActive: (state) => { state.makeAddressActiveRequestStatus = 'pending'; },
    sendRequestMakeAddressActiveSuccess: (state) => { state.makeAddressActiveRequestStatus = 'resolved'; },
    sendRequestMakeAddressActiveError: (state) => { state.makeAddressActiveRequestStatus = 'rejected'; },
    clearMakeAddressActiveStatus: (state) => { state.makeAddressActiveRequestStatus = null; },

    sendRequestClientDevicesGet: (state) => { state.clientDevicesGetRequestStatus = 'pending'; },
    sendRequestClientDevicesGetSuccess: (state) => { state.clientDevicesGetRequestStatus = 'resolved'; },
    sendRequestClientDevicesGetError: (state) => { state.clientDevicesGetRequestStatus = 'rejected'; },
    clearClientDevicesGetStatus: (state) => { state.clientDevicesGetRequestStatus = null; },
    setClientDevices: (state, action) => {
      state.clientDevices = action.payload;
    },

    sendRequestPushNotificationGet: (state) => { state.pushNotificationGetRequestStatus = 'pending'; },
    sendRequestPushNotificationGetSuccess: (state) => { state.pushNotificationGetRequestStatus = 'resolved'; },
    sendRequestPushNotificationGetError: (state) => { state.pushNotificationGetRequestStatus = 'rejected'; },
    clearPushNotificationGetStatus: (state) => { state.pushNotificationGetRequestStatus = null; },
    setPushNotification: (state, action) => {
      state.pushNotification = action.payload;
    },

    sendRequestMessagesGet: (state) => { state.messagesGetRequestStatus = 'pending'; },
    sendRequestMessagesGetSuccess: (state) => { state.messagesGetRequestStatus = 'resolved'; },
    sendRequestMessagesGetError: (state) => { state.messagesGetRequestStatus = 'rejected'; },
    clearMessagesGetStatus: (state) => { state.messagesGetRequestStatus = null; },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
  },
});

export const {
  //Search
  sendRequestClientsGet,
  sendRequestClientsGetSuccess,
  sendRequestClientsGetError,
  clearClientsGetStatus,
  setClients,
  setCurrentPage,

  //Detail
  sendRequestClientProfileGet,
  sendRequestClientProfileGetSuccess,
  sendRequestClientProfileGetError,
  clearClientProfileGetStatus,
  setClientProfile,

  sendRequestClientOrdersStatisticsGet,
  sendRequestClientOrdersStatisticsGetSuccess,
  sendRequestClientOrdersStatisticsGetError,
  clearClientOrdersStatisticsGetStatus,
  setClientOrdersStatistics,

  sendRequestClientOrdersGet,
  sendRequestClientOrdersGetSuccess,
  sendRequestClientOrdersGetError,
  clearClientOrdersGetStatus,
  setClientOrders,
  setCurrentOrdersPage,

  sendRequestClientAddressesGet,
  sendRequestClientAddressesGetSuccess,
  sendRequestClientAddressesGetError,
  clearClientAddressesGetStatus,
  setClientAddresses,
  sendRequestMakeAddressActive,
  sendRequestMakeAddressActiveSuccess,
  sendRequestMakeAddressActiveError,
  clearMakeAddressActiveStatus,

  sendRequestClientDevicesGet,
  sendRequestClientDevicesGetSuccess,
  sendRequestClientDevicesGetError,
  clearClientDevicesGetStatus,
  setClientDevices,

  sendRequestPushNotificationGet,
  sendRequestPushNotificationGetSuccess,
  sendRequestPushNotificationGetError,
  clearPushNotificationGetStatus,
  setPushNotification,

  sendRequestMessagesGet,
  sendRequestMessagesGetSuccess,
  sendRequestMessagesGetError,
  clearMessagesGetStatus,
  setMessages,
} = clientsSearchSlice.actions;

export default clientsSearchSlice.reducer;