import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestCitiesGet } from '../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { sendRequestKitchens } from '../../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { 
	getKitchensSettings,
	getAdminsSettings,
	renameKitchen,
	createKitchenPlashka,
	deleteKitchenPlashka,
	changeCityKitchen,
	getAllKitchenStatuses,
	changeStatusKitchen,
	getKitchenNotWorking,
	updateKitchensSettings,
} from '../../../../redux/reducers/utils/kitchenReducers/kitchenSettingsReducer';
import styled from 'styled-components';
import KitchenSelectorComponent from '../../components/KitchenSelectorComponent';
import KitchenNameComponent from './components/KitchenNameComponent';
import ImageComponent from './components/ImageComponent';
import SoloSelectorComponent from './components/SoloSelectorComponent';
import NotWorkingDatePicker from '../../../../components/NotWorkingDatePicker';
import { checkAccess } from '../../../../core/utils';
import AdminCheckList from './components/AdminCheckList';
import { Tabs } from 'antd';
import { Container, Page, ScrolledCol, Title, VerticalSpace } from '../../../../core/styles';
import Card from '../../../../components/Card';
import SettingsForm from './components/SettingsForm';
import Flex from '../../../../components/Flex';
import AdminsSettingsForm from './components/AdminsSettingsForm';

const { TabPane } = Tabs;

const SettingsPage = () => {
	const { isAuth } = useSelector((store) => store.auth);
	const { accessComponentsForUser } = useSelector((store) => store.services);
	const { cities } = useSelector((store) => store.settingsCatalogs);
	const { selectKitchen } = useSelector((store) => store.kitchen);
	const { allKitchenStatuses, kitchensSettingGetRequestStatus, kitchensSetting } = useSelector((store) => store.kitchenSettings);
	const dispatch = useDispatch();
	const isAdmin = checkAccess(accessComponentsForUser, 'moderation');

	useEffect(() => {
		if (isAuth) {
			dispatch(sendRequestKitchens({ token: localStorage.getItem('token'), offset: 0, limit: 10000 }));
			dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
			dispatch(getAllKitchenStatuses({ token: localStorage.getItem('token') }));
		}
	}, [isAuth]);

	useEffect(() => {
		if(selectKitchen) {
			dispatch(getKitchensSettings({token: localStorage.getItem('token'), kitchenId: selectKitchen.id}))
			dispatch(getKitchenNotWorking({ token: localStorage.getItem('token'), kitchenId: selectKitchen.id }));
			if(isAdmin) {
				dispatch(getAdminsSettings({token: localStorage.getItem('token'), kitchenId: selectKitchen.id}));
			}
		}
	}, [selectKitchen])

	const statusOptions = allKitchenStatuses.map((status) => { return {value: status.id, label: status.name} });
	const cityOptions = cities.map((city) => { return {value: city.id, label: city.name} });

  	const handleUploadImage = (uploadFile) => {
		const requestData = {
			token: localStorage.getItem('token'),
			kitchenId: selectKitchen.id,
			image: uploadFile,
		}

		dispatch(createKitchenPlashka(requestData))
		.then(() => {
			return dispatch(sendRequestKitchens({
				token: localStorage.getItem('token'),
				offset: 0, limit: 10000
			}));
		})
  	}

  	const handleRemoveImage = () => {
		const requestData = {
			token: localStorage.getItem('token'),
			kitchenId: selectKitchen.id,
		}

		dispatch(deleteKitchenPlashka(requestData))
		.then(() => {
			return dispatch(sendRequestKitchens({
				token: localStorage.getItem('token'),
				offset: 0, limit: 10000
			}));
		})
  	}

  	const handleChangeKitchenName = (name) => {
		const requestData = {
			token: localStorage.getItem('token'),
			kitchenId: selectKitchen.id,
			name: name,
		}

		dispatch(renameKitchen(requestData))
		.then(() => {
			return dispatch(sendRequestKitchens({
				token: localStorage.getItem('token'),
				offset: 0, limit: 10000
			}));
		})
  	}

  	const handleChangeKitchenCity = (cityId) => {
		const requestData = {
			token: localStorage.getItem('token'),
			kitchenId: selectKitchen.id,
			cityId: cityId,
		}

		dispatch(changeCityKitchen(requestData))
		.then(() => {
			return dispatch(sendRequestKitchens({
				token: localStorage.getItem('token'),
				offset: 0, limit: 10000
			}));
		})
  	}

  	const handleChangeKitchenStatus = (statusId) => {
		const requestData = {
			token: localStorage.getItem('token'),
			kitchenId: selectKitchen.id,
			statusId: statusId,
		}

		dispatch(changeStatusKitchen(requestData))
		.then(() => {
			return dispatch(sendRequestKitchens({
				token: localStorage.getItem('token'),
				offset: 0, limit: 10000
			}));
		})
  	}

  	return (
		<Page>
			<ScrolledCol>
				<Container>
					<VerticalSpace>

						<Header>
							<KitchenSelectorComponent/>
							<Title>Настройки</Title>
						</Header>

						<Flex style={{marginBottom: '32px'}}>
							<ImageComponent
								isAdmin={isAdmin}
								onUploadImage={handleUploadImage}
								onRemoveImage={handleRemoveImage}
							/>
						</Flex>

						{isAdmin ? (
							<Tabs defaultActiveKey="1">
								<TabPane tab="Настройки" key="1">
									<SettingsForm/>

									<Card title='Не рабочие дни' style={{flex: '1 0 auto', marginTop: '32px'}}>
										<NotWorkingDatePicker/>
									</Card>
								</TabPane>
								<TabPane tab="Для админов" key="2">
									<Flex column gap={'20px'} wrap={'wrap'}>
										<KitchenNameComponent
											isAdmin={isAdmin}
											data={selectKitchen?.name}
											onChange={handleChangeKitchenName}
										/>

										<SoloSelectorComponent
											isAdmin={isAdmin}
											title='Город'
											data={selectKitchen?.cityId}
											options={cityOptions}
											onChange={handleChangeKitchenCity}
										/>

										<SoloSelectorComponent
											isAdmin={isAdmin}
											title='Статус'
											data={selectKitchen?.status}
											options={statusOptions}
											onChange={handleChangeKitchenStatus}
										/>

										<Flex gap={'32px'} wrap={'wrap'}>
											<AdminsSettingsForm/>
											<AdminCheckList/>
										</Flex>
									</Flex>
								</TabPane>
							</Tabs>
						) : (
							<>
								<SettingsForm/>

								<Card title='Не рабочие дни' style={{flex: '1 0 auto', marginTop: '32px'}}>
									<NotWorkingDatePicker/>
								</Card>
							</>
						)}

					</VerticalSpace>
				</Container>
			</ScrolledCol>
		</Page>
	);
};

export default SettingsPage;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	gap: 50px;
	margin-bottom: 50px;
`
