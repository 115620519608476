import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import apiInstance from '../../../../api';
import { selectCommonState } from '../commonReducer';

export const getKitchensSettings = createAsyncThunk(
  'kitchenSettings/getKitchensSettings',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/settings/getSettingsByKitchenId?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const updateKitchensSettings = createAsyncThunk(
  'kitchenSettings/updateKitchensSettings',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/settings/updateSettingsByKitchenId?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const getAdminsSettings = createAsyncThunk(
  'kitchenSettings/getAdminsSettings',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/settings/getSettingsAdminByKitchenId?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data.kitchenSettings;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const updateAdminsSettings = createAsyncThunk(
  'kitchenSettings/updateAdminsSettings',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/settings/updateAdminSettingsByKitchenId?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const renameKitchen = createAsyncThunk(
  'kitchenSettings/renameKitchen',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/settings/renameKitchen?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const createKitchenPlashka = createAsyncThunk(
  'kitchenSettings/createKitchenPlashka',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/settings/creteKitchenPlashka?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    let formData = new FormData();
    formData.append('image', data.image);
    formData.append('data', JSON.stringify(data));

    try {
      const response = await apiInstance.post(fullApi, formData);

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const deleteKitchenPlashka = createAsyncThunk(
  'kitchenSettings/deleteKitchenPlashka',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/settings/deleteKitchenPlashka?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const changeCityKitchen = createAsyncThunk(
  'kitchenSettings/changeCityKitchen',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/changeCityKitchen?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const getAllKitchenStatuses = createAsyncThunk(
  'kitchenSettings/getAllKitchenStatuses',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/getAllKitchenStatuses?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data.statuses;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const changeStatusKitchen = createAsyncThunk(
  'kitchenSettings/changeStatusKitchen',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/changeStatusKitchen?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const getKitchenNotWorking = createAsyncThunk(
  'kitchenSettings/getKitchenNotWorking',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/settings/kitchenNotWorking/get?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data.kitchenNotWorking;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const createKitchenNotWorking = createAsyncThunk(
  'kitchenSettings/createKitchenNotWorking',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/settings/kitchenNotWorking/create?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const updateKitchenNotWorking = createAsyncThunk(
  'kitchenSettings/updateKitchenNotWorking',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/settings/kitchenNotWorking/update?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const deleteKitchenNotWorking = createAsyncThunk(
  'kitchenSettings/deleteKitchenNotWorking',
  async (data, { getState, rejectWithValue }) => {
    const api = '/kitchens/settings/kitchenNotWorking/delete?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

const initialState = {
  kitchensSettingGetRequestStatus: null,
  kitchensSettingUpdateRequestStatus: null,
  adminsSettingGetRequestStatus: null,
  adminsSettingUpdateRequestStatus: null,
  renameKitchenRequestStatus: null,
  createKitchenPlashkaRequestStatus: null,
  deleteKitchenPlashkaRequestStatus: null,
  changeCityKitchenRequestStatus: null,
  getAllKitchenStatusesRequestStatus: null,
  changeStatusKitchenRequestStatus: null,
  kitchenNotWorkingGetRequestStatus: null,
  kitchenNotWorkingCreateRequestStatus: null,
  kitchenNotWorkingUpdateRequestStatus: null,
  kitchenNotWorkingDeleteRequestStatus: null,

  kitchensSetting: null,
  adminsSetting: [],
  allKitchenStatuses: [],
  kitchenNotWorking: [],
  
  //ContractsPage
  kitchenContractsGetRequestStatus: null,
  kitchenContracts: [],
  kitchenContractsCreateRequestStatus: null,
  kitchenContractsUpdateRequestStatus: null,
  kitchenContractsDeleteRequestStatus: null,

  kitchenContractCommissionsGetRequestStatus: null,
  kitchenContractCommissions: [],
  kitchenContractCommissionsCreateRequestStatus: null,
  kitchenContractCommissionsUpdateRequestStatus: null,
  kitchenContractCommissionsDeleteRequestStatus: null,
};

const kitchenSettingsSlice = createSlice({
  name: 'kitchenSettings',
  initialState,
  extraReducers: (builder) => {
    builder
      // getKitchensSettings
      .addCase(getKitchensSettings.pending, (state) => { state.kitchensSettingGetRequestStatus = 'pending'; })
      .addCase(getKitchensSettings.fulfilled, (state, action) => {
        state.kitchensSettingGetRequestStatus = 'resolved';
        state.kitchensSetting = action.payload;
      })
      .addCase(getKitchensSettings.rejected, (state) => { state.kitchensSettingGetRequestStatus = 'rejected'; })

      // updateKitchensSettings
      .addCase(updateKitchensSettings.pending, (state) => { state.kitchensSettingUpdateRequestStatus = 'pending'; })
      .addCase(updateKitchensSettings.fulfilled, (state) => { state.kitchensSettingUpdateRequestStatus = 'resolved'; })
      .addCase(updateKitchensSettings.rejected, (state) => { state.kitchensSettingUpdateRequestStatus = 'rejected'; })

      // getAdminsSettings
      .addCase(getAdminsSettings.pending, (state) => { state.adminsSettingGetRequestStatus = 'pending'; })
      .addCase(getAdminsSettings.fulfilled, (state, action) => {
        state.adminsSettingGetRequestStatus = 'resolved';
        state.adminsSetting = action.payload;
      })
      .addCase(getAdminsSettings.rejected, (state) => { state.adminsSettingGetRequestStatus = 'rejected'; })

      // updateAdminsSettings
      .addCase(updateAdminsSettings.pending, (state) => { state.adminsSettingUpdateRequestStatus = 'pending'; })
      .addCase(updateAdminsSettings.fulfilled, (state) => { state.adminsSettingUpdateRequestStatus = 'resolved'; })
      .addCase(updateAdminsSettings.rejected, (state) => { state.adminsSettingUpdateRequestStatus = 'rejected'; })

      // updateAdminsSettings
      .addCase(renameKitchen.pending, (state) => { state.renameKitchenRequestStatus = 'pending'; })
      .addCase(renameKitchen.fulfilled, (state) => { state.renameKitchenRequestStatus = 'resolved'; })
      .addCase(renameKitchen.rejected, (state) => { state.renameKitchenRequestStatus = 'rejected'; })

      // createKitchenPlashka
      .addCase(createKitchenPlashka.pending, (state) => { state.createKitchenPlashkaRequestStatus = 'pending'; })
      .addCase(createKitchenPlashka.fulfilled, (state) => { state.createKitchenPlashkaRequestStatus = 'resolved'; })
      .addCase(createKitchenPlashka.rejected, (state) => { state.createKitchenPlashkaRequestStatus = 'rejected'; })

      // createKitchenPlashka
      .addCase(deleteKitchenPlashka.pending, (state) => { state.deleteKitchenPlashkaRequestStatus = 'pending'; })
      .addCase(deleteKitchenPlashka.fulfilled, (state) => { state.deleteKitchenPlashkaRequestStatus = 'resolved'; })
      .addCase(deleteKitchenPlashka.rejected, (state) => { state.deleteKitchenPlashkaRequestStatus = 'rejected'; })

      // changeCityKitchen
      .addCase(changeCityKitchen.pending, (state) => { state.changeCityKitchenRequestStatus = 'pending'; })
      .addCase(changeCityKitchen.fulfilled, (state) => { state.changeCityKitchenRequestStatus = 'resolved'; })
      .addCase(changeCityKitchen.rejected, (state) => { state.changeCityKitchenRequestStatus = 'rejected'; })

      // getAllKitchenStatuses
      .addCase(getAllKitchenStatuses.pending, (state) => { state.getAllKitchenStatusesRequestStatus = 'pending'; })
      .addCase(getAllKitchenStatuses.fulfilled, (state, action) => {
        state.getAllKitchenStatusesRequestStatus = 'resolved';
        state.allKitchenStatuses = action.payload;
      })
      .addCase(getAllKitchenStatuses.rejected, (state) => { state.getAllKitchenStatusesRequestStatus = 'rejected'; })

      // changeStatusKitchen
      .addCase(changeStatusKitchen.pending, (state) => { state.changeStatusKitchenRequestStatus = 'pending'; })
      .addCase(changeStatusKitchen.fulfilled, (state) => { state.changeStatusKitchenRequestStatus = 'resolved'; })
      .addCase(changeStatusKitchen.rejected, (state) => { state.changeStatusKitchenRequestStatus = 'rejected'; })

      // getKitchenNotWorking
      .addCase(getKitchenNotWorking.pending, (state) => { state.kitchenNotWorkingGetRequestStatus = 'pending'; })
      .addCase(getKitchenNotWorking.fulfilled, (state, action) => {
        state.kitchenNotWorkingGetRequestStatus = 'resolved';
        state.kitchenNotWorking = action.payload;
      })
      .addCase(getKitchenNotWorking.rejected, (state) => { state.kitchenNotWorkingGetRequestStatus = 'rejected'; })

      // createKitchenNotWorking
      .addCase(createKitchenNotWorking.pending, (state) => { state.kitchenNotWorkingCreateRequestStatus = 'pending'; })
      .addCase(createKitchenNotWorking.fulfilled, (state) => { state.kitchenNotWorkingCreateRequestStatus = 'resolved'; })
      .addCase(createKitchenNotWorking.rejected, (state) => { state.kitchenNotWorkingCreateRequestStatus = 'rejected'; })

      // updateKitchenNotWorking
      .addCase(updateKitchenNotWorking.pending, (state) => { state.kitchenNotWorkingUpdateRequestStatus = 'pending'; })
      .addCase(updateKitchenNotWorking.fulfilled, (state) => { state.kitchenNotWorkingUpdateRequestStatus = 'resolved'; })
      .addCase(updateKitchenNotWorking.rejected, (state) => { state.kitchenNotWorkingUpdateRequestStatus = 'rejected'; })

      // deleteKitchenNotWorking
      .addCase(deleteKitchenNotWorking.pending, (state) => { state.kitchenNotWorkingDeleteRequestStatus = 'pending'; })
      .addCase(deleteKitchenNotWorking.fulfilled, (state) => { state.kitchenNotWorkingDeleteRequestStatus = 'resolved'; })
      .addCase(deleteKitchenNotWorking.rejected, (state) => { state.kitchenNotWorkingDeleteRequestStatus = 'rejected'; })

      // PURGE
      .addCase(PURGE, () => { return initialState; })
  },
  reducers: {
    //Contracts page
    sendRequestKitchenContractsGet: (state) => { state.kitchenContractsGetRequestStatus = 'pending'; },
    sendRequestKitchenContractsGetSuccess: (state) => { state.kitchenContractsGetRequestStatus = 'resolved'; },
    sendRequestKitchenContractsGetError: (state) => { state.kitchenContractsGetRequestStatus = 'rejected'; },
    clearKitchenContractsGetStatus: (state) => { state.kitchenContractsGetRequestStatus = null; },
    setKitchenContracts: (state, action) => {
      state.kitchenContracts = action.payload;
    },
    sendRequestKitchenContractsCreate: (state) => { state.kitchenContractsCreateRequestStatus = 'pending'; },
    sendRequestKitchenContractsCreateSuccess: (state) => { state.kitchenContractsCreateRequestStatus = 'resolved'; },
    sendRequestKitchenContractsCreateError: (state) => { state.kitchenContractsCreateRequestStatus = 'rejected'; },
    clearKitchenContractsCreateStatus: (state) => { state.kitchenContractsCreateRequestStatus = null; },
    sendRequestKitchenContractsUpdate: (state) => { state.kitchenContractsUpdateRequestStatus = 'pending'; },
    sendRequestKitchenContractsUpdateSuccess: (state) => { state.kitchenContractsUpdateRequestStatus = 'resolved'; },
    sendRequestKitchenContractsUpdateError: (state) => { state.kitchenContractsUpdateRequestStatus = 'rejected'; },
    clearKitchenContractsUpdateStatus: (state) => { state.kitchenContractsUpdateRequestStatus = null; },
    sendRequestKitchenContractsDelete: (state) => { state.kitchenContractsDeleteRequestStatus = 'pending'; },
    sendRequestKitchenContractsDeleteSuccess: (state) => { state.kitchenContractsDeleteRequestStatus = 'resolved'; },
    sendRequestKitchenContractsDeleteError: (state) => { state.kitchenContractsDeleteRequestStatus = 'rejected'; },
    clearKitchenContractsDeleteStatus: (state) => { state.kitchenContractsDeleteRequestStatus = null; },

    sendRequestKitchenContractCommissionsGet: (state) => { state.kitchenContractCommissionsGetRequestStatus = 'pending'; },
    sendRequestKitchenContractCommissionsGetSuccess: (state) => { state.kitchenContractCommissionsGetRequestStatus = 'resolved'; },
    sendRequestKitchenContractCommissionsGetError: (state) => { state.kitchenContractCommissionsGetRequestStatus = 'rejected'; },
    clearKitchenContractCommissionsGetStatus: (state) => { state.kitchenContractCommissionsGetRequestStatus = null; },
    setKitchenContractCommissions: (state, action) => {
      state.kitchenContractCommissions = action.payload;
    },
    sendRequestKitchenContractCommissionsCreate: (state) => { state.kitchenContractCommissionsCreateRequestStatus = 'pending'; },
    sendRequestKitchenContractCommissionsCreateSuccess: (state) => { state.kitchenContractCommissionsCreateRequestStatus = 'resolved'; },
    sendRequestKitchenContractCommissionsCreateError: (state) => { state.kitchenContractCommissionsCreateRequestStatus = 'rejected'; },
    clearKitchenContractCommissionsCreateStatus: (state) => { state.kitchenContractCommissionsCreateRequestStatus = null; },
    sendRequestKitchenContractCommissionsUpdate: (state) => { state.kitchenContractCommissionsUpdateRequestStatus = 'pending'; },
    sendRequestKitchenContractCommissionsUpdateSuccess: (state) => { state.kitchenContractCommissionsUpdateRequestStatus = 'resolved'; },
    sendRequestKitchenContractCommissionsUpdateError: (state) => { state.kitchenContractCommissionsUpdateRequestStatus = 'rejected'; },
    clearKitchenContractCommissionsUpdateStatus: (state) => { state.kitchenContractCommissionsUpdateRequestStatus = null; },
    sendRequestKitchenContractCommissionsDelete: (state) => { state.kitchenContractCommissionsDeleteRequestStatus = 'pending'; },
    sendRequestKitchenContractCommissionsDeleteSuccess: (state) => { state.kitchenContractCommissionsDeleteRequestStatus = 'resolved'; },
    sendRequestKitchenContractCommissionsDeleteError: (state) => { state.kitchenContractCommissionsDeleteRequestStatus = 'rejected'; },
    clearKitchenContractCommissionsDeleteStatus: (state) => { state.kitchenContractCommissionsDeleteRequestStatus = null; },
  },
});

export const {
  //Contracts page
  sendRequestKitchenContractsGet,
  sendRequestKitchenContractsGetSuccess,
  sendRequestKitchenContractsGetError,
  clearKitchenContractsGetStatus,
  setKitchenContracts,
  sendRequestKitchenContractsCreate,
  sendRequestKitchenContractsCreateSuccess,
  sendRequestKitchenContractsCreateError,
  clearKitchenContractsCreateStatus,
  sendRequestKitchenContractsUpdate,
  sendRequestKitchenContractsUpdateSuccess,
  sendRequestKitchenContractsUpdateError,
  clearKitchenContractsUpdateStatus,
  sendRequestKitchenContractsDelete,
  sendRequestKitchenContractsDeleteSuccess,
  sendRequestKitchenContractsDeleteError,
  clearKitchenContractsDeleteStatus,

  sendRequestKitchenContractCommissionsGet,
  sendRequestKitchenContractCommissionsGetSuccess,
  sendRequestKitchenContractCommissionsGetError,
  clearKitchenContractCommissionsGetStatus,
  setKitchenContractCommissions,
  sendRequestKitchenContractCommissionsCreate,
  sendRequestKitchenContractCommissionsCreateSuccess,
  sendRequestKitchenContractCommissionsCreateError,
  clearKitchenContractCommissionsCreateStatus,
  sendRequestKitchenContractCommissionsUpdate,
  sendRequestKitchenContractCommissionsUpdateSuccess,
  sendRequestKitchenContractCommissionsUpdateError,
  clearKitchenContractCommissionsUpdateStatus,
  sendRequestKitchenContractCommissionsDelete,
  sendRequestKitchenContractCommissionsDeleteSuccess,
  sendRequestKitchenContractCommissionsDeleteError,
  clearKitchenContractCommissionsDeleteStatus,
} = kitchenSettingsSlice.actions;

export default kitchenSettingsSlice.reducer;
