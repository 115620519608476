import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getAllProducts, addMenu, updateAllProducts, toggleMenuStatus } from '../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import styled from 'styled-components';
import BackButton from '../../../../ui/BackButton';
import { Container, Page, ScrolledCol, VerticalSpace } from '../../../../core/styles';
import { CalendarIcon, DownloadIcon, PlayIcon, StopIcon, TimeIcon, UsertimeIcon, WarningIcon } from '../../../../components/Icons';
import Title from '../../../../ui/Title';
import Flex from '../../../../components/Flex';
import ProductTabs from './components/ProductTabs';
import Menu from './components/Menu';
import Card from '../../../../components/Card';
import Text from '../../../../ui/Text';
import Button from '../../../../ui/Button';
import Tooltip from '../../../../ui/Tooltip';
import moment from 'moment';
import { Period } from '../dashboardPage/components/DaysStatistics';
import MenuStats from './components/MenuStats';

const MenuPage = () => {
    const navigate = useNavigate();
    const { isMobile, serverTime } = useSelector((store) => store.common);
    const { isAuth } = useSelector((store) => store.auth);
    const { selectKitchen } = useSelector((store) => store.kitchen);
    const { allProducts, currentRace } = useSelector((store) => store.kitchenMenu);
    const dispatch = useDispatch();
    const [isEdited, setIsEdited] = useState(currentRace?.menu.length === 0 ? true : false);
    const [haveMenu, setHaveMenu] = useState(currentRace?.menu.length > 0 ? true : false);
    const [isBlocked, setIsBlocked] = useState(currentRace?.menu[0]?.status === 'draft');
    const timezone = currentRace?.timezoneCity;
    const adjustedTimeStart = moment(currentRace?.timeStart, "HH:mm").add(timezone, 'hours').format("HH:mm");
    const adjustedTimeEnd = moment(currentRace?.timeEnd, "HH:mm").add(timezone, 'hours').format("HH:mm");
    const scrolledColRef = useRef(null);
    const [showMenuInfo, setShowMenuInfo] = useState(false);
    const activeProducts = allProducts.filter(product => product.status === 'on');
    const productsWithoutPhoto = activeProducts.filter(product => !product.filePathPublic);
    const productsWithoutKBJU = activeProducts.filter(product => !product.calories || !product.carbs || !product.fats || !product.proteins);
    const noBread = activeProducts.every(product => product.categoryId !== 50);
    const noCutlery = activeProducts.every(product => product.categoryId !== 10);
    
    console.log(allProducts, )

    const stats = {
        totalProducts: activeProducts.length,
        withoutPhoto: productsWithoutPhoto.length,
        withoutKBJU: productsWithoutKBJU.length,
        noBread: noBread,
        noCutlery: noCutlery,
    };

    if (!currentRace) {
        navigate('/kitchen/dashboard');
    }

    useEffect(() => {
		if (currentRace) {
            const requestData = {
                token: localStorage.getItem('token'),
                day: currentRace.deliveryDay,
                kitchenId: currentRace.kitchenId,
                raceId: currentRace.raceId,
            }
        
            dispatch(getAllProducts(requestData));
		}
	}, [currentRace]);

    const handleSaveProducts = (updateProducts, modifiedProducts, statusMenu = 'publish') => {
        const requestData = {
            token: localStorage.getItem('token'),
            kitchenId: currentRace.kitchenId,
            menu: {
                days: [
                    {
                        day: currentRace.deliveryDay,
                        races: [
                            {
                                raceId: 1,
                                statusMenu: statusMenu, //'publish' /'draft'
                                disableOldProducts: false, //Для копирования меню на другие дни. Нужно указать на какую дату
                                products: modifiedProducts
                                    .map(({ productId, price, amountForOrder, status }) => ({
                                        productId,
                                        price,
                                        amountForOrder: amountForOrder || 0,
                                        status
                                    })),
                            }
                        ]
                    }
                ]
            }
        }
    
        dispatch(addMenu(requestData))
            .then(() => {
                dispatch(updateAllProducts(updateProducts));
                setIsEdited(false);
                setHaveMenu(true);
            })
            .then(() => {
                scrolledColRef.current.scrollTop = 0;
            })
            .catch((error) => {
                console.error("Ошибка при добавлении меню:", error);
            });
    }

    const stopMenu = () => {
        dispatch(toggleMenuStatus({
            token: localStorage.getItem('token'),
            menuId: currentRace.menu[0].id,
            enabled: true,
        }))
        .then(() => {
            setIsBlocked(true);
        })
        .catch((error) => {
            console.error("Ошибка при обновлении статуса меню:", error);
        });
    }

    const startMenu = () => {
        dispatch(toggleMenuStatus({
            token: localStorage.getItem('token'),
            menuId: currentRace.menu[0].id,
            enabled: false,
        }))
        .then(() => {
            setIsBlocked(false);
        })
        .catch((error) => {
            console.error("Ошибка при обновлении статуса меню:", error);
        });
    }

    const handleUpdateProduct = (updateProducts, updateProduct) => {
        handleSaveProducts(updateProducts, [updateProduct]);
    }

    if (isMobile) {
        return (
            <Page>
                <ScrolledCol ref={scrolledColRef}>
                    <MobileHeader>
                        <BackButton
                            text={haveMenu ? isEdited ? 'Опубликованное меню' : 'Главная страница' : 'Главная страница'}
                            link={haveMenu ? isEdited ? undefined : '/kitchen/dashboard' : '/kitchen/dashboard'}
                            onClick={haveMenu ? isEdited ? () => setIsEdited(false) : () => dispatch(updateAllProducts([])) : () => dispatch(updateAllProducts([]))}
                        />
                        {isEdited ?
                            <CalendarIcon width='32px' height='32px' color='#b2b2b2'/>
                        :
                            <>
                                {isBlocked ?
                                    <Tooltip title={'Возобновить приём заказов'} text={'На данный момент заказы приостановлены'} placement="topRight" open={true}>
                                        <div><Button style={{minWidth: '32px', height: '32px', padding: '9px', borderRadius: '12px'}} type='icon' color='#49BC9A' onClick={startMenu}><PlayIcon width='14px' height='14px'/></Button></div>
                                    </Tooltip>
                                :
                                    <Tooltip title={'Приостановить приём заказов'} text={'Меню не будет удалено'} placement="topRight">
                                        <div><Button style={{minWidth: '32px', height: '32px', padding: '9px', borderRadius: '12px'}} type='icon' color='#EA394B' onClick={stopMenu}><StopIcon width='14px' height='14px'/></Button></div>
                                    </Tooltip>
                                }
                            </>
                        }
                    </MobileHeader>

                    {isEdited ?
                        <Card style={{flex: '0 0 auto', marginBottom: '16px'}}>
                            <Flex column align='flex-start'>
                                <Title level={2}>{selectKitchen.name}</Title>
                                <Title level={3}>{moment(currentRace.deliveryDay).format('dddd D MMMM')}</Title>
                                <Flex align={'center'} gap={'10px'} style={{marginTop: '20px', textWrap: 'nowrap'}}>
                                    <Text size='16px'>{adjustedTimeStart}-{adjustedTimeEnd}</Text>
                                    <div style={{color: '#cccccc'}}>|</div>
                                    <Flex align={'center'} gap={'8px'}>
                                        <UsertimeIcon width='20px' height='20px' color={'#FF8800'}/>
                                        <Text size='16px' color='#FF8800'>10:15</Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Card>
                    : null}

                    {isEdited ? 
                        <ProductTabs products={allProducts} onSave={handleSaveProducts}/>
                    : 
                        <Flex column gap='24px'>
                            <Menu isBlocked={isBlocked} currentRace={currentRace} timeStart={adjustedTimeStart} timeEnd={adjustedTimeEnd} products={allProducts} onUpdateProduct={handleUpdateProduct}/>
                            <MenuStats stats={stats}/>
                            <Card style={{flex: '0 0 auto'}}>
                                <MenuActions>
                                    <Button color='#2A2A2A' style={{width: '100%'}} onClick={() => setIsEdited(true)}>Редактировать</Button>
                                    <Button color='#49BC9A' style={{width: '100%'}} onClick={() => setShowMenuInfo(!showMenuInfo)}>Заказы <DownloadIcon width='20px' height='20px'/></Button>
                                </MenuActions>
                            </Card>

                            {showMenuInfo &&
                                <PopupMenuInfo onClick={() => setShowMenuInfo(!showMenuInfo)}>
                                    <div style={{width: '100%'}} onClick={(e) => e.stopPropagation()}>
                                        <MenuInfo>
                                            <Title level={3}>Заказы</Title>
                                            <Flex>
                                                {currentRace?.periods && currentRace?.periods.length > 0 ?
                                                    <Flex column gap={'10px'} style={{width: '100%'}}>
                                                        {currentRace?.periods.map((period) => (
                                                            <Period period={period} day={currentRace?.deliveryDay} serverTime={serverTime} timezone={timezone}/>
                                                        ))}
                                                    </Flex>
                                                : currentRace?.isWorkRace && currentRace?.menu.length === 0 ?
                                                    <InfoTitle>
                                                        <TimeIcon/>
                                                        <Text size='14px'>Время вышло, рекомендуем<br/>публиковать меню заранее</Text>
                                                    </InfoTitle>
                                                : currentRace?.isWorkRace && currentRace?.menu.length === 0 ?
                                                    <InfoTitle>
                                                        <WarningIcon/>
                                                        <Text size='14px'>Меню пока никто не<br/>опубликовал, будьте первыми</Text>
                                                    </InfoTitle>
                                                : null}
                                            </Flex>
                                        </MenuInfo>
                                    </div>
                                </PopupMenuInfo>
                            }
                        </Flex>
                    }
                </ScrolledCol>
            </Page>
        )
    }

    return (
        <Page>
			<ScrolledCol ref={scrolledColRef}>
				<Container>
					<VerticalSpace>

                        <Header>
                            <Flex column>
                                <BackButton
                                    text={haveMenu ? isEdited ? 'Опубликованное меню' : 'Главная страница' : 'Главная страница'}
                                    link={haveMenu ? isEdited ? undefined : '/kitchen/dashboard' : '/kitchen/dashboard'}
                                    onClick={haveMenu ? isEdited ? () => setIsEdited(false) : () => dispatch(updateAllProducts([])) : () => dispatch(updateAllProducts([]))}
                                />
                                {isEdited && <Title level={2} style={{marginTop: '12px'}}>{selectKitchen.name}</Title>}
                            </Flex>
                            {isEdited &&
                                <Flex column align='flex-end'>
                                    <Title level={1}><CalendarIcon width='36px' height='36px' color='#b2b2b2'/> {moment(currentRace.deliveryDay).format('dddd D MMMM')}</Title>
                                    <Flex align={'center'} gap={'10px'} style={{marginTop: '20px', textWrap: 'nowrap'}}>
                                        <Title level={2}>{adjustedTimeStart}-{adjustedTimeEnd}</Title>
                                        <div style={{color: '#cccccc'}}>|</div>
                                        <Flex align={'center'} gap={'8px'}>
                                            <UsertimeIcon color={'#FF8800'}/>
                                            <Title level={2} color='#FF8800'>10:15</Title>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            }
                        </Header>

                        {isEdited ? 
                            <ProductTabs products={allProducts} onSave={handleSaveProducts}/>
                        :
                            <Flex gap='24px'>
                                <Flex flex='2'>
                                    <Menu isBlocked={isBlocked} currentRace={currentRace} timeStart={adjustedTimeStart} timeEnd={adjustedTimeEnd} products={allProducts} onUpdateProduct={handleUpdateProduct}/>
                                </Flex>
                                <Flex column flex='1' gap='24px'>
                                    <MenuActions>
                                        <Button color='#2A2A2A' style={{width: '100%'}} onClick={() => setIsEdited(true)}>Редактировать меню</Button>
                                        {isBlocked ?
                                            <Tooltip title={'Возобновить приём заказов'} text={'На данный момент заказы приостановлены'} placement="topRight" open={true}>
                                                <div><Button type='icon' color='#49BC9A' onClick={startMenu}><PlayIcon/></Button></div>
                                            </Tooltip>
                                        :
                                            <Tooltip title={'Приостановить приём заказов'} text={'Меню не будет удалено'} placement="topRight">
                                                <div><Button type='icon' color='#EA394B' onClick={stopMenu}><StopIcon/></Button></div>
                                            </Tooltip>
                                        }
                                    </MenuActions>
                                    <MenuInfo>
                                        <Title level={3}>Заказы</Title>
                                        <Flex>
                                            {currentRace?.periods && currentRace?.periods.length > 0 ?
                                                <Flex column gap={'10px'} style={{width: '100%'}}>
                                                    {currentRace?.periods.map((period) => (
                                                        <Period period={period} day={currentRace?.deliveryDay} serverTime={serverTime} timezone={timezone}/>
                                                    ))}
                                                </Flex>
                                            : currentRace?.isWorkRace && currentRace?.menu.length === 0 ?
                                                <InfoTitle>
                                                    <TimeIcon/>
                                                    <Text size='14px'>Время вышло, рекомендуем<br/>публиковать меню заранее</Text>
                                                </InfoTitle>
                                            : currentRace?.isWorkRace && currentRace?.menu.length === 0 ?
                                                <InfoTitle>
                                                    <WarningIcon/>
                                                    <Text size='14px'>Меню пока никто не<br/>опубликовал, будьте первыми</Text>
                                                </InfoTitle>
                                            : null}
                                        </Flex>
                                    </MenuInfo>
                                    
                                    <MenuStats stats={stats}/>

                                </Flex>
                            </Flex>
                        }

                    </VerticalSpace>
				</Container>
			</ScrolledCol>
		</Page>
    );
};

export default MenuPage;

const MobileHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    padding: 20px 16px;
    width: 100%;
    background: #ffffff;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    overflow: hidden;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 32px;
    flex-wrap: wrap;
    margin-bottom: 32px;
`
const MenuActions = styled.div`
    display: flex;
    gap: 16px;
`
const PopupMenuInfo = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 24px;
    background: #08080A66;
`
const MenuInfo = styled(Card)`
    flex: 0 0 auto;
`
const InfoTitle = styled.div`
    display: flex;
    gap: 10px;
    font-size: 14px;
`
