import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Divider } from 'antd';
import { Container, Page, ScrolledCol, VerticalSpace } from '../../../../core/styles';
import Loader from '../../../../components/Loader'
import FileManagerTableComponent from './components/FileManagerTableComponent';
import FileManagerFiltersComponent from './components/FileManagerFiltersComponent';

const { Title } = Typography;

const FileManagerPage = () => {
    const {
        filesGetRequestStatus,
    } = useSelector(state => state.servicesFileManager);

    return (
        <Page>
            <ScrolledCol>
                <Container>
                    <VerticalSpace>
                        {(
                            filesGetRequestStatus === 'pending'
                        ) && <Loader />}
                        <Title level={2}>Управление файлами</Title>
                        <FileManagerFiltersComponent/>
                        <Divider/>
                        <FileManagerTableComponent/>
                    </VerticalSpace>
                </Container>
            </ScrolledCol>
        </Page>
    );
};

export default FileManagerPage;
