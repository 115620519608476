import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllKitchenStatuses, getKitchensSettings, updateKitchensSettings } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenSettingsReducer';
import styled from 'styled-components';
import { Input, InputNumber, Button, Spin, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import { Text, Title } from '../../../../../core/styles';
import Flex from '../../../../../components/Flex';
import Card from '../../../../../components/Card';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

const SettingsForm = () => {
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const { kitchensSettingGetRequestStatus, kitchensSetting } = useSelector((store) => store.kitchenSettings);
  const dispatch = useDispatch();
  const [settingsAccess, setSettingsAccess] = useState([]);
  const [settingsData, setSettingsData] = useState(null);
  const [initialSettingsData, setInitialSettingsData] = useState(null);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (kitchensSetting) {
      if (kitchensSetting && kitchensSetting.column) {
        setSettingsAccess(kitchensSetting.column);
      }
      if (kitchensSetting && kitchensSetting.kitchenSettings) {
        const normalizeSettings = (settings) => {
          const normalizedSettings = {};
          for (const key in settings) {
            if (settings.hasOwnProperty(key)) {
              normalizedSettings[key] = settings[key] ?? '';
            }
          }
          return normalizedSettings;
        };
      
        const settings = {
          ...normalizeSettings(kitchensSetting.kitchenSettings),
          kitchenAccessNumbers: kitchensSetting.kitchenSettings.kitchenAccessNumbers?.split(';') || [],
          testerNumbers: kitchensSetting.kitchenSettings.testerNumbers?.split(';') || [],
          longitude: kitchensSetting.kitchenSettings.longitude || 49.35939594058916,
          latitude: kitchensSetting.kitchenSettings.latitude || 53.52730593794812,
        }
        setSettingsData(settings);
        setInitialSettingsData(settings);
      }
    }
  }, [kitchensSetting]);

  const handleInputChange = (field, value) => {
    setSettingsData((prev) => ({
      ...prev,
      [field]: String(value),
    }));
    setIsModified(true);
  };

  const handleMapClick = (e) => {
    const coords = e.get('coords');

    setSettingsData({
      ...settingsData,
      latitude: coords[0],
      longitude: coords[1],
    });
    setIsModified(true);
  };

  const handleSave = () => {
    dispatch(updateKitchensSettings({
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
      ...settingsData,
      longitude: String(settingsData.longitude),
      latitude: String(settingsData.latitude)
    }))
    .then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        message.success('Обновлено!');
        setInitialSettingsData(settingsData);
      }
    })
    .catch((error) => {
      console.error(error);
    });
    setIsModified(false);
  };

  const handleCancel = () => {
    setSettingsData(initialSettingsData);
    setIsModified(false);
  };

  const coords = settingsData && settingsData?.latitude && settingsData?.longitude ? [settingsData?.latitude, settingsData?.longitude] : [53.52730593794812, 49.35939594058916];
  return (
    <>
      {kitchensSettingGetRequestStatus === 'pending' ?
        <Card style={{justifyContent: 'center', minHeight: '40vh'}}><Spin/></Card>
      :
          <Flex
            column
            gap={'32px'}
          >
            {/* Реквизиты для договора и выплат */}
            <Card title={<Title level={4}>Реквизиты для договора и выплат</Title>}>
              <Flex gap='20px' wrap='wrap'>
                <FieldWrapper>
                  <label>Название организации или ИП</label>
                  <Input
                    value={settingsData?.orgName || ''}
                    onChange={(e) => handleInputChange('orgName', e.target.value)}
                    placeholder='Введите название организации'
                    disabled={!settingsAccess.includes('orgName')}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <label>Контактный номер</label>
                  <Input
                    value={settingsData?.contactPhone || ''}
                    onChange={(e) => handleInputChange('contactPhone', e.target.value)}
                    placeholder='Введите контактный номер'
                    disabled={!settingsAccess.includes('contactPhone')}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <label>Контактное лицо</label>
                  <Input
                    value={settingsData?.contactPerson || ''}
                    onChange={(e) => handleInputChange('contactPerson', e.target.value)}
                    placeholder='Введите контактное лицо'
                    disabled={!settingsAccess.includes('contactPerson')}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <label>ИНН</label>
                  <InputNumber
                    value={settingsData?.inn || ''}
                    onChange={(value) => handleInputChange('inn', value)}
                    placeholder="Введите ИНН"
                    disabled={!settingsAccess.includes('inn')}
                    min={0}
                    precision={0}
                    style={{ width: '100%' }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <label>КПП</label>
                  <InputNumber
                    value={settingsData?.kpp || ''}
                    onChange={(value) => handleInputChange('kpp', value)}
                    placeholder="Введите КПП"
                    disabled={!settingsAccess.includes('kpp')}
                    min={0}
                    precision={0}
                    style={{ width: '100%' }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <label>Расчетный счет</label>
                  <InputNumber
                    value={settingsData?.rs || ''}
                    onChange={(value) => handleInputChange('rs', value)}
                    placeholder="Введите расчетный счет"
                    disabled={!settingsAccess.includes('rs')}
                    min={0}
                    precision={0}
                    style={{ width: '100%' }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <label>Корреспондентский счет</label>
                  <InputNumber
                    value={settingsData?.ks || ''}
                    onChange={(value) => handleInputChange('ks', value)}
                    placeholder="Введите корреспондентский счет"
                    disabled={!settingsAccess.includes('ks')}
                    min={0}
                    precision={0}
                    style={{ width: '100%' }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <label>Фактический адрес</label>
                  <Input
                    value={settingsData?.factAddress || ''}
                    onChange={(e) => handleInputChange('factAddress', e.target.value)}
                    placeholder='Укажите фактический адрес'
                    disabled={!settingsAccess.includes('factAddress')}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <label>Юридический адрес</label>
                  <Input
                    value={settingsData?.urAddress || ''}
                    onChange={(e) => handleInputChange('urAddress', e.target.value)}
                    placeholder='Укажите юридический адрес'
                    disabled={!settingsAccess.includes('urAddress')}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <label>Название банка</label>
                  <Input
                    value={settingsData?.bankName || ''}
                    onChange={(e) => handleInputChange('bankName', e.target.value)}
                    placeholder='Введите название банка'
                    disabled={!settingsAccess.includes('bankName')}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <label>Бик банка</label>
                  <InputNumber
                    value={settingsData?.bankBik || ''}
                    onChange={(value) => handleInputChange('bankBik', value)}
                    placeholder="Введите бик банка"
                    disabled={!settingsAccess.includes('bankBik')}
                    min={0}
                    precision={0}
                    style={{ width: '100%' }}
                  />
                </FieldWrapper>
              </Flex>
            </Card>

            {/* Электронная почта */}
            <Card title={<Title level={4}>Электронная почта для:</Title>}>
              <Flex gap='20px' wrap='wrap'>
                <FieldWrapper>
                  <label>Новых заказов</label>
                  <Input
                    value={settingsData?.emailOrders || ''}
                    onChange={(e) => handleInputChange('emailOrders', e.target.value)}
                    placeholder='Введите email для актов и документов'
                    disabled={!settingsAccess.includes('emailOrders')}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <label>Актов и иных документов</label>
                  <Input
                    value={settingsData?.emailDocuments || ''}
                    onChange={(e) => handleInputChange('emailDocuments', e.target.value)}
                    placeholder='Введите email для актов и документов'
                    disabled={!settingsAccess.includes('emailDocuments')}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <label>Отзывов</label>
                  <Input
                    value={settingsData?.emailReviews || ''}
                    onChange={(e) => handleInputChange('emailReviews', e.target.value)}
                    placeholder='Введите email для отзывов'
                    disabled={!settingsAccess.includes('emailReviews')}
                  />
                </FieldWrapper>
              </Flex>
            </Card>

            <Flex gap={'32px'} wrap={'wrap'}>
              <div style={{flex: '3 1 330px'}}>
                {/* Информация о кухне */}
                <Card title={<Title level={4}>Информация о кухне (то что видно клиенту)</Title>}>
                  <Flex gap='20px' wrap='wrap'>
                    <FieldWrapper>
                      <label>Адрес</label>
                      <Input
                        value={settingsData?.kitchenAddress || ''}
                        onChange={(e) => handleInputChange('kitchenAddress', e.target.value)}
                        placeholder='Введите адрес кухни'
                        disabled={!settingsAccess.includes('kitchenAddress')}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <label>Телефон</label>
                      <Input
                        value={settingsData?.kitchenPhone || ''}
                        onChange={(e) => handleInputChange('kitchenPhone', e.target.value)}
                        placeholder='Введите телефон кухни'
                        disabled={!settingsAccess.includes('kitchenPhone')}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <label>Группа ВК</label>
                      <Input
                        value={settingsData?.vkGroup || ''}
                        onChange={(e) => handleInputChange('vkGroup', e.target.value)}
                        placeholder='Ссылка на группу ВК'
                        disabled={!settingsAccess.includes('vkGroup')}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <label>Инстаграм</label>
                      <Input
                        value={settingsData?.instagram || ''}
                        onChange={(e) => handleInputChange('instagram', e.target.value)}
                        placeholder='Ссылка на инстаграм'
                        disabled={!settingsAccess.includes('instagram')}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <label>Сайт</label>
                      <Input
                        value={settingsData?.website || ''}
                        onChange={(e) => handleInputChange('website', e.target.value)}
                        placeholder='Ссылка на сайт'
                        disabled={!settingsAccess.includes('website')}
                      />
                    </FieldWrapper>

                    <Flex style={{width: '100%'}} justify={'flex-end'}>{coords[0]}, {coords[1]}</Flex>
                    <YMaps
                      query={{
                        apikey: 'e119accb-b623-4fe2-9b13-6f8e128efb5f',
                        lang: 'ru_RU',
                        load: 'package.full',
                      }}
                    >
                      <Map
                        state={{ center: coords, zoom: 10 }}
                        onClick={handleMapClick}
                        style={{ width: '100%', height: '400px' }}
                      >
                        <Placemark geometry={coords} />
                      </Map>
                    </YMaps>

                  </Flex>
                </Card>
              </div>


              <div style={{flex: '1 1 330px'}}>
                {/* Настройки кухни */}
                <Card title={<Title level={4}>Настройки кухни</Title>}>
                  <Flex gap='20px' wrap='wrap'>
                    <FieldWrapper>
                      <label>Номера тестировщиков</label>
                      <Input
                        value={settingsData?.testerNumbers || ''}
                        onChange={(e) => handleInputChange('testerNumbers', e.target.value)}
                        placeholder='Введите телефон тестировщика'
                        disabled={!settingsAccess.includes('testerNumbers')}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <label>Номера телефонов кому доступна кухня</label>
                      <Input
                        value={settingsData?.kitchenAccessNumbers || ''}
                        onChange={(e) => handleInputChange('kitchenAccessNumbers', e.target.value)}
                        placeholder='Введите номер телефона'
                        disabled={!settingsAccess.includes('kitchenAccessNumbers')}
                      />
                    </FieldWrapper>
                  </Flex>
                </Card>
              </div>
            </Flex>

            {isModified && (
              <ActionWrapper>
                  <Title level={5}>Сохранить изменения?</Title>
                  <Flex gap='20px'>
                      <Button type="primary" onClick={handleSave}>Сохранить</Button>
                      <Button onClick={handleCancel}>Отменить</Button>
                  </Flex>
              </ActionWrapper>
            )}
          </Flex>
      }
    </>
  );
};

export default SettingsForm;

const ActionWrapper = styled.div`
    position: fixed;
    bottom: 100px;
    right: 100px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fit-content;
    padding: 24px;
    background: #ffffff;
    border-radius: 24px;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, .15);
`

const FieldWrapper = styled.div`
  flex: 1 1 330px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
