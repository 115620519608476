import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Image from '../../../../../ui/Image';
import { Container } from '../../../../../core/styles';
import Text from '../../../../../ui/Text';
import Button from '../../../../../ui/Button';
import { useSelector } from 'react-redux';
import { CheckIcon, IIcon } from '../../../../../components/Icons';
import Tooltip from '../../../../../ui/Tooltip';
import InputNumber from '../../../../../ui/InputNumber';
import { getCorrectWordForm } from '../../../../../core/utils';

const ProductTabs = ({products, onSave}) => {
    const { isMobile } = useSelector((store) => store.common);
    const [activeTab, setActiveTab] = useState('');
    const [categories, setCategories] = useState([]);
    const [productList, setProductList] = useState([]);
    const [initialProductList, setInitialProductList] = useState([]);
    const [isModified, setIsModified] = useState(false);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const groupedProducts = products.reduce((acc, product) => {
            const { categoryName } = product;
            if (!acc[categoryName]) {
                acc[categoryName] = [];
            }
            acc[categoryName].push(product);
            return acc;
        }, {});

        const updatedProducts = applyAlwaysPublish(products);

        setProductList(updatedProducts);
        setInitialProductList(products);
        setCategories(Object.keys(groupedProducts));
        setActiveTab(Object.keys(groupedProducts)[0]);
    }, [products]);

    const applyAlwaysPublish = (list) => {
        return list.map((product) => {
            if (product.alwaysPublish === 1 && !product.inMenu) {
                return { ...product, status: 'on' };
            }
            return product;
        });
    };

    useEffect(() => {
        const hasProductsWithoutPrice = productList.some((product) => product.status === 'on' && (product.price === '' || Number(product.price) === 0));
        setHasError(hasProductsWithoutPrice);

        const modified = JSON.stringify(productList) !== JSON.stringify(initialProductList);
        setIsModified(modified);
    }, [productList, initialProductList]);

    const handleStatusToggle = (e, productId) => {
        setProductList((prev) => {
            const updatedList = prev.map((product) =>
                product.productId === productId
                    ? { ...product, status: product.status === 'on' ? 'off' : 'on' }
                    : product
            );
            setIsModified(true);
            return updatedList;
        });
    };

    const handleInputChange = (productId, field, value) => {
        setProductList((prev) => {
            const updatedList = prev.map((product) =>
                product.productId === productId ? { ...product, [field]: value, status: 'on' } : product
            );
            setIsModified(true);
            return updatedList;
        });
    };

    const getModifiedProducts = () => {
        return productList.filter((product, index) => {
            const initialProduct = initialProductList[index];
            return JSON.stringify(product) !== JSON.stringify(initialProduct);
        });
    };

    const handleSave = () => {
        onSave(productList, getModifiedProducts());
        setInitialProductList(productList);
        setIsModified(false);
    };

    const handleCancel = () => {
        setProductList(initialProductList);
        setIsModified(false);
    };

    return (
        <Wrapper isMobile={isMobile}>
            <TabList isMobile={isMobile}>
                {categories.map((category) => {
                    const enabledCount = productList.filter((product) => product.categoryName === category && product.status === 'on').length;
                    const errorProductPrice = productList.some((product) => product.categoryName === category && product.status === 'on' && (product.price === '' || Number(product.price) === 0));
                    
                    return (
                        <Tab
                            key={category}
                            active={activeTab === category}
                            onClick={() => setActiveTab(category)}
                        >
                            {category} {enabledCount > 0 && <span style={{background: errorProductPrice ? '#EA394B' : '#49BC9A'}}>{enabledCount}</span>}
                        </Tab>
                    );
                })}
            </TabList>
            <ProductList isMobile={isMobile}>
                {productList
                    .filter((product) => product.categoryName === activeTab)
                    .map((product) => (
                        <ProductCard
                            key={product.productId}
                            product={product}
                            initialPrice={initialProductList.find(initProduct => initProduct.productId === product.productId).price}
                            onToggleStatus={(e) => handleStatusToggle(e, product.productId)}
                            onInputChange={handleInputChange}
                        />
                    ))}
            </ProductList>

            {isModified && isMobile ?
                <MobileActionBarWrapper>
                    <MobileActionBar>
                        <Tooltip title={'Публикация невозможна'} text={'У некоторых позиций отсутствует цена, исправьте во вкладках с красной меткой'} open={hasError}>
                            <div><Button style={{width: '100%'}} color='red' disabled={hasError} onClick={handleSave}>Опубликовать меню</Button></div>
                        </Tooltip>
                        <Button onClick={handleCancel}>Отменить</Button>
                    </MobileActionBar>
                </MobileActionBarWrapper>
            : isModified ?
                <ActionBarWrapper>
                    <Container>
                        <ActionBar>
                            <Text style={{flex: 1}} size='24px'>Выбрано: {productList.filter((product) => product.status === 'on').length}</Text>
                            <Button onClick={handleCancel}>Отменить</Button>
                            <Tooltip title={'Публикация невозможна'} text={'У некоторых позиций отсутствует цена, исправьте во вкладках с красной меткой'} open={hasError}>
                                <div><Button color='red' disabled={hasError} onClick={handleSave}>Опубликовать меню</Button></div>
                            </Tooltip>
                        </ActionBar>
                    </Container>
                </ActionBarWrapper>
            : null}
        </Wrapper>
    );
};

export default ProductTabs;

const ProductCard = ({ product, initialPrice, onToggleStatus, onInputChange }) => {
    const { isMobile } = useSelector((store) => store.common);
    const hasErrorPrice = product.status === 'on' && (product.price === '' || Number(product.price) === 0);
    const isHighlighted = initialPrice && Math.abs(product.price - initialPrice) / initialPrice > 0.5 || false;
    const checked = product.status === 'on';
    const warningHaveOrders = product.status === 'off' && product.amountOrdered > 0;

    return (
        <ProductWrapper checked={checked} onClick={onToggleStatus}>
            <Image src={product.filePathPublic} alt={product.name} size={isMobile ? 'small' : 'medium'} onClick={(e) => e.stopPropagation()}/>
            <ProductName isMobile={isMobile}>{product.name}</ProductName>
            <div style={{position: 'relative'}} onClick={(e) => e.stopPropagation()}>
                <InputNumber
                    placeholder='руб'
                    min={0}
                    initialValue={product.price}
                    formatter={value => value ? `${value} ₽` : ''}
                    width={isMobile ? '65px' : '90px'}
                    error={hasErrorPrice}
                    onEnter={(value) => onInputChange(product.productId, 'price', value)}
                />
                {isHighlighted &&
                    <LabelWrapper>
                        <Tooltip
                            title={'Внимание! Цена значительно изменилась.'}
                            text={`Ранее было: ${initialPrice} ₽`}
                            placement='topRight'
                        >
                            <Label><IIcon height='14px'/></Label>
                        </Tooltip>
                    </LabelWrapper>
                }
            </div>
            <div style={{position: 'relative'}} onClick={(e) => e.stopPropagation()}>
                <InputNumber
                    placeholder={product.amountForOrder_old || '∞'} //'∞'
                    min={0}
                    initialValue={product.amountForOrder === '' ? product.amountForOrder_old : product.amountForOrder}
                    formatter={value => value > 0 ? `${value} шт` : '∞'}
                    width={isMobile ? '65px' : '90px'}
                    onEnter={(value) => onInputChange(product.productId, 'amountForOrder', value)}
                />
                {product.amountForOrder > 0 && product.amountOrdered_old + 3 > product.amountForOrder &&
                    <LabelWrapper>
                        <Tooltip
                            title={`Рекомендуем увеличить на ${product.amountOrdered_old - product.amountForOrder + 3}`}
                            text={`Эта позиция предыдущий раз продалась в количестве ${product.amountOrdered_old} шт`}
                            placement='topRight'
                        >
                            <Label>+{product.amountOrdered_old - product.amountForOrder + 3}</Label>
                        </Tooltip>
                    </LabelWrapper>
                }
            </div>
            {!isMobile &&
                <Tooltip
                    title={warningHaveOrders ? `Внимание! Этот товар уже заказали ${getCorrectWordForm(product.amountOrdered, ['раз', 'раза', 'раз'])}` : ''}
                    text={warningHaveOrders ? 'Отключение его из меню не отменяет заказы. Если вы не можете приготовить заказы с этим товаром, то срочно свяжитесь с менеджером Сытого для отмены заказов или будьте готовы передать их курьеру' : ''}
                    placement='topRight'
                >
                    <Status
                        checked={checked}
                        warning={warningHaveOrders}
                    >
                        {warningHaveOrders ?
                            <IIcon/>
                        :
                            <CheckIcon/>
                        }
                    </Status>
                </Tooltip>
            }
        </ProductWrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({isMobile}) => isMobile ? '20px' : '32px'};
`;

const TabList = styled.div`
    display: flex;
    flex-wrap: ${({isMobile}) => isMobile ? 'nowrap' : 'wrap'};
    gap: 10px;
    padding-bottom: 5px;
    overflow: ${({isMobile}) => isMobile ? 'auto' : 'hidden'};
`;

const Tab = styled.button`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 16px;
    height: 45px;
    border: none;
    border-radius: 32px;
    background: ${({ active }) => (active ? '#333333' : '#ffffff')};
    border: solid 1px #eaeaea;
    font-size: 18px;
    color: ${({ active }) => (active ? '#fff' : '#333333')};
    transition: all .3s;
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 20px;
        height: 20px;
        font-size: 14px;
        color: #ffffff;
        border-radius: 20px;
    }
    &:hover {
        background: ${({ active }) => (!active ? '#e4e4e4' : '#333333')};
        border: solid 1px ${({ active }) => (!active ? '#e4e4e4' : '#333333')};
    }
`;

const ProductList = styled.div`
    display: grid;
    grid-template-columns: ${({isMobile}) => isMobile ? '1fr' : 'repeat(2, minmax(300px, 1fr))'};
    gap: ${({isMobile}) => isMobile ? '10px' : '16px'};
`;

const MobileActionBarWrapper = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2000;
    width: 100%;
    padding: 8px 16px 36px;
    background: #ffffff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
`;

const MobileActionBar = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: 100%;
`;

const ActionBarWrapper = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2000;
    width: calc(100% - 80px);
    height: 80px;
    background: #ffffff;
`;

const ActionBar = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    height: 100%;
`;

const ProductWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: 100%;
    padding: 16px;
    background: ${({ checked }) => (checked ? '#B7EDDD' : '#ffffff')};
    border: 1px solid #EAEAEA;
    border-radius: 24px;
    transition: all .3s;
    overflow: hidden;
    cursor: pointer;
`;

const ProductName = styled.div`
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 16px;
    font-weight: ${({isMobile}) => isMobile ? '400' : 'bold'};
    overflow: hidden;
`;

const LabelWrapper = styled.div`
    position: absolute;
    top: -16px;
    right: -24px;
    z-index: 1;
`;

const Label = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    height: 32px;
    background: #FF8800;
    border: none;
    border-radius: 32px;
    font-size: 14px;
    color: #ffffff;
    transition: all .3s;
`;

const Status = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 44px;
    height: 44px;
    background: ${({ checked, warning }) => (warning ? '#FF8800' : checked ? '#45a049' : '#EAEAEA')};
    color: ${({ checked, warning }) => (warning ? '#ffffff' : checked ? '#ffffff' : '#B2B2B2')};
    border: none;
    border-radius: 44px;
    transition: all .3s;
    &:hover {
        background: ${({ checked, warning }) => (warning ? '#FF8800' : checked ? '#3e8e41' : '#e4e4e4')};
    }
    &:active {
        background: #45a049;
    }
`;
