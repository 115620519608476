import { all } from 'redux-saga/effects';
import { authWatcherSaga } from './utils/authSaga/authSaga';
import { kitchenWatcherSaga } from './utils/kitchenSaga/kitchenSaga/kitchenSaga';
import { kitchenFinanceWatcherSaga } from './utils/kitchenSaga/kitchenFinanceSaga/kitchenFinanceSaga';
import { kitchenComplaintWatcherSaga } from './utils/kitchenSaga/kitchenComplaintSaga/kitchenComplaintSaga';
import { kitchenMenuWatcherSaga } from './utils/kitchenSaga/kitchenMenuSaga/kitchenMenuSaga';
import { kitchenAssortmentWatcherSaga } from './utils/kitchenSaga/kitchenAssortmentSaga/kitchenAssortmentSaga';
import { kitchenSettingsWatcherSaga } from './utils/kitchenSaga/kitchenSettingsSaga/kitchenSettingsSaga';
import { settingsCatalogsWatcherSaga } from './utils/settingsSaga/settingsCatalogsSaga/settingsCatalogsSaga';
import { deliveryServicesWatcherSaga } from './utils/deliverySaga/deliveryServicesSaga/deliveryServicesSaga';
import { deliveryMapWatcherSaga } from './utils/deliverySaga/deliveryMapSaga/deliveryMapSaga';
import { clientsSearchWatcherSaga } from './utils/clientsSaga/clientsSearchSaga/clientsSearchSaga';
import { clientsNotificationsWatcherSaga } from './utils/clientsSaga/clientsNotificationsSaga/clientsNotificationsSaga';
import { addressesSearchWatcherSaga } from './utils/clientsSaga/addressesSearchSaga/addressesSearchSaga';
import { financeWalletsWatcherSaga } from './utils/financeSaga/financeWalletsSaga/financeWalletsSaga';
import { financePromocodesWatcherSaga } from './utils/financeSaga/financePromocodesSaga/financePromocodesSaga';
import { financeCorpclientsWatcherSaga } from './utils/financeSaga/financeCorpclientsSaga/financeCorpclientsSaga';
import { financialSettlementWatcherSaga } from './utils/moderationSaga/financialSettlementSaga/financialSettlementSaga';
import { servicesWatcherSaga } from './utils/servicesSaga/servicesSaga/servicesSaga';
import { servicesLogsWatcherSaga } from './utils/servicesSaga/servicesLogsSaga/servicesLogsSaga';

export default function* combineSaga() {
  yield all([
    authWatcherSaga(),
    kitchenWatcherSaga(),
    kitchenFinanceWatcherSaga(),
    kitchenComplaintWatcherSaga(),
    kitchenMenuWatcherSaga(),
    kitchenAssortmentWatcherSaga(),
    kitchenSettingsWatcherSaga(),
    settingsCatalogsWatcherSaga(),
    deliveryServicesWatcherSaga(),
    deliveryMapWatcherSaga(),
    clientsSearchWatcherSaga(),
    clientsNotificationsWatcherSaga(),
    addressesSearchWatcherSaga(),
    financeWalletsWatcherSaga(),
    financePromocodesWatcherSaga(),
    financeCorpclientsWatcherSaga(),
    financialSettlementWatcherSaga(),
    servicesWatcherSaga(),
    servicesLogsWatcherSaga(),
  ]);
}
