import React, { useState } from 'react';
import { GraphiQL } from 'graphiql';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { explorerPlugin } from '@graphiql/plugin-explorer';
import 'graphiql/graphiql.css';
import '@graphiql/plugin-explorer/dist/style.css';
import { Select } from 'antd';

const { Option } = Select;

const GraphqlPage = () => {
    const [serverUrl, setServerUrl] = useState('https://api-test.menuforme.online/api/graphql/5496880-8345687-2394984-221781/admin');

    const handleServerUrlChange = (value) => {
        setServerUrl(value);
    };

    const fetcher = createGraphiQLFetcher({
        url: serverUrl,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="serverUrl">Server URL:</label>
            <Select
                id="serverUrl"
                value={serverUrl}
                onChange={handleServerUrlChange}
                style={{ width: '100%', margin: '8px 0' }}
            >
                <Option value="https://api-test.menuforme.online/api/graphql/5496880-8345687-2394984-221781/admin">
                    api-test.menuforme.online (Боевой)
                </Option>
                <Option value="https://bottest2.menu4me.ru/api/graphql/5496880-8345687-2394984-221781/admin">
                    bottest2.menu4me.ru
                </Option>
                <Option value="https://dolina.menuforme.online/api/graphql/5496880-8345687-2394984-221781/admin">
                    dolina.menuforme.online
                </Option>
            </Select>
            <div style={{ height: 'calc(100vh - 115px)'}}>
                <GraphiQL fetcher={fetcher} plugins={[explorerPlugin()]}/>
            </div>
        </div>
    );
}

export default GraphqlPage;