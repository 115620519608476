import React, { useEffect, useState } from 'react';
import { Modal, Input, Button, Typography, message, Flex } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setIsLogsModalOpen, setSelectedOrder } from '../../../../../../redux/reducers/utils/financeReducers/financeOrdersReducer';
import ImgUploader from '../../../../../../components/ImgUploader';
import { formatDate } from '../../../../../../core/utils';

const { TextArea } = Input;
const { Title, Text } = Typography;

const LogsModal = () => {
  const dispatch = useDispatch();
  const {
    isLogsModalOpen,
    selectedOrder,
  } = useSelector((state) => state.financeOrders);

  const handleModalClose = () => {
    dispatch(setIsLogsModalOpen(false));
    dispatch(setSelectedOrder(null));
  };

  const renderJson = (data, indent = 0) => {
    if (typeof data === 'object' && data !== null) {
      return Object.entries(data).map(([key, value]) => (
        <div key={key} style={{ paddingLeft: indent * 20 }}>
          <Text type="secondary">{key}: </Text>
          {typeof value === 'object' ? renderJson(value, indent + 1) : <Text>{value}</Text>}
        </div>
      ));
    }
    return <Text>{data}</Text>;
  };

  return (
    <Modal
      title="Логи"
      open={isLogsModalOpen}
      onCancel={handleModalClose}
      footer={null}
    >
        {selectedOrder && selectedOrder.orderLogs.length > 0 ? (
            <List>
                {selectedOrder.orderLogs.map(item => (
                    <ListItem>
                        <Text>{item.event}</Text>
                        <Text type='secondary'>{formatDate(item.created)}</Text>
                        <div>{renderJson(JSON.parse(item.extra_info))}</div>
                    </ListItem>
                ))}
            </List>
        ) : (
            <Text>Нет логов для отображения</Text>
        )}
    </Modal>
  );
};

export default LogsModal;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    border-bottom: none;
  }
`
