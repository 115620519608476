import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentRoute } from '../../redux/reducers/utils/navReducer';

function KitchenModule() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/kitchen') {
      dispatch(setCurrentRoute('/kitchen/dashboard'));
      navigate('/kitchen/dashboard');
    }
  }, [location.pathname]);
}

export default KitchenModule;
