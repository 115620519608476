import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectKitchen } from '../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import styled from 'styled-components';
import { Text, Title } from '../../../core/styles';
import { ShevronDonwIcon, TimeBlockedIcon } from '../../../components/Icons';
import { Input } from 'antd';
import Tooltip from '../../../ui/Tooltip';

const KitchenSelectorComponent = () => {
	const {kitchens, selectKitchen} = useSelector((store) => store.kitchen);
	const dispatch = useDispatch();
	const [activeKitchen, setActiveKitchen] = useState(null);
	const [open, setOpen] = useState(false);
	const buttonRef = useRef(null);
	const dropdownRef = useRef(null);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		if(selectKitchen) {
			setActiveKitchen(selectKitchen);
		}
	}, [selectKitchen])

	const toggleDropdown = () => setOpen(!open);

	const handleSelectKitchen = (kitchen) => {
		dispatch(setSelectKitchen(kitchen))
		setOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current && !dropdownRef.current.contains(event.target) && 
				buttonRef.current && !buttonRef.current.contains(event.target) // Проверяем, что клик не на кнопке
			) {
				setOpen(false);
			}
		};

		if (open) {
			document.addEventListener('click', handleClickOutside);
		}

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [open]);

	const handleSearch = (e) => {
		setSearchTerm(e.target.value.toLowerCase());
	};

	const filteredKitchens = kitchens.filter(kitchen => 
		kitchen.name.toLowerCase().includes(searchTerm) || 
		kitchen.cityName.toLowerCase().includes(searchTerm)
	);

	const renderKitchens = () => {
		if (kitchens.length === 1) {
			return (
				<Label>
					<LabelName>
						<Title style={{ marginBottom: '10px' }}>{kitchens[0]?.name}</Title>
						<Text secondary>{kitchens[0]?.cityName}</Text>
					</LabelName>
					{kitchens[0]?.status === 3 || kitchens[0]?.status === 4 || kitchens[0]?.status === 5 ?
						<Tooltip title={'Модерация'} text={'Кухня пока на стадии подключения'}>
							<Status>
								<TimeBlockedIcon color='#b2b2b2'/>
							</Status>
						</Tooltip>
					: 
						null
					}
				</Label>
			);
		}

		if (kitchens.length > 1 && kitchens.length <= 3) {
			return kitchens.map((kitchen, index) => (
				<Label style={{paddingBottom: '16px', borderBottom: kitchen.id === activeKitchen?.id ? 'solid 3px var(--color-font)' : 'solid 3px #EAEAEA'}} key={index} active={kitchen.id === activeKitchen?.id} onClick={() => handleSelectKitchen(kitchen)}>
					<LabelName>
						<Title color={kitchen.id === activeKitchen?.id ? 'var(--color-font)' : '#858080'} level={3} style={{ marginBottom: '10px' }}>{kitchen.name}</Title>
						<Text color={kitchen.id === activeKitchen?.id ? 'var(--color-font)' : '#858080'} size='14px' secondary>{kitchen.cityName}</Text>
					</LabelName>
					{kitchen?.status === 3 || kitchen?.status === 4 || kitchen?.status === 5 ?
						<Tooltip title={'Модерация'} text={'Кухня пока на стадии подключения'}>
							<Status>
								<TimeBlockedIcon color='#b2b2b2'/>
							</Status>
						</Tooltip>
					: 
						null
					}
				</Label>
			));
		}

		if (kitchens.length > 3) {
			return (
				<>
					<Label ref={buttonRef} onClick={toggleDropdown}>
						<LabelName>
							<Title style={{ marginBottom: '10px' }}>{activeKitchen?.name}</Title>
							<Text secondary>{activeKitchen?.cityName}</Text>
						</LabelName>
						{activeKitchen?.status === 3 || activeKitchen?.status === 4 || activeKitchen?.status === 5 ?
							<Tooltip title={'Модерация'} text={'Кухня пока на стадии подключения'}>
								<Status>
									<TimeBlockedIcon color='#b2b2b2'/>
								</Status>
							</Tooltip>
						: 
							null
						}
						<LabelIcon open={open}>
							<ShevronDonwIcon />
						</LabelIcon>
					</Label>
					<Dropdown ref={dropdownRef} open={open}>
						<Input
							style={{marginBottom: '20px', width: 'calc(100% - 16px)'}}
							placeholder='Поиск'
							value={searchTerm}
							onChange={handleSearch}
						/>
						<DropdownList>
							{filteredKitchens.map((kitchen, index) => (
								<DropdownItem
									key={index}
									active={kitchen.id === activeKitchen?.id}
									onClick={() => handleSelectKitchen(kitchen)}
								>
									<div>
										<Title style={{ marginBottom: '5px' }} level={5}>
											{kitchen.name}
										</Title>
										<Text size="12px" secondary>
											{kitchen.cityName}
										</Text>
									</div>
									{kitchen.status === 3 || kitchen.status === 4 || kitchen.status === 5 ?
										<Tooltip title={'Модерация'} text={'Кухня пока на стадии подключения'}>
											<Status>
												<TimeBlockedIcon color='#b2b2b2'/>
											</Status>
										</Tooltip>
									: 
										null
									}
								</DropdownItem>
							))}
						</DropdownList>
					</Dropdown>
				</>
			);
		}
	};

	return (
		<KitchenSelector>{renderKitchens()}</KitchenSelector>
	);
};

export default KitchenSelectorComponent;

const KitchenSelector = styled.div`
  position: relative;
  display: flex;
  min-width: 275px;
`
const Label = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-left: 8px;
  padding-right: 50px;
  cursor: pointer;
  user-select: none;
  &:first-child {
    padding-left: 0;
  }
`
const LabelName = styled.div`
  display: flex;
  flex-direction: column;
`
const LabelIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  height: 45px;
  background: #ffffff;
  border-radius: 45px;
  transition: all .3s;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
`
const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background: #ffffff;
  border-radius: 32px;
  width: 100%;
  box-shadow: 3px 6px 47px 0px #00000012;
  list-style: none;
  padding: 32px 0 32px 16px;
  display: flex;
  flex-direction: column;
  transition: all .2s ease-out;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? '1' : '0')};
`
const DropdownList = styled.ul`
  width: 100%;
  height: 275px;
  padding-right: 16px;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #999999;
		border-radius: 5px;
		transition: all 0.3s ease;
	}
	&::-webkit-scrollbar-track {
		background-color: #f5f5f5;
		border: 1px solid #f5f5f5;
	}
`
const DropdownItem = styled.li`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  background: ${({ active, locked }) => (active ? '#EAEAEA66' : locked ? '#f5f5f5' : '#ffffff')};
  border-bottom: solid 1px #EAEAEA;
  color: ${({ locked }) => (locked ? '#ccc' : 'var(--color-font)')};
  transition: all .3s;
  cursor: ${({ locked }) => (locked ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${({ locked }) => (locked ? '#f5f5f5' : '#eee')};
  }
`
const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  height: 45px;
  background: #ffffff;
  border-radius: 45px;
`