import React from 'react';
import { Button, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { disableTokenByLike, getClientTokens } from '../../../../../redux/reducers/utils/clientsReducers/clientsSearchReducer';

const TokensTableComponent = ({ dataSource }) => {
  const dispatch = useDispatch();

  const handleDeleteToken = (userId, userToken) => {
    const requestData = {
      token: localStorage.getItem('token'),
      userId,
      userToken,
    };

    console.log(requestData);
    dispatch(disableTokenByLike(requestData))
      .then(() => {
        dispatch(getClientTokens({
          token: localStorage.getItem('token'),
          userId,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      title: 'ID пользователя',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'Логин',
      dataIndex: 'login',
      key: 'login',
    },
    {
      title: 'Тип логина',
      dataIndex: 'loginType',
      key: 'loginType',
    },
    {
      title: 'Статус',
      dataIndex: 'statusName',
      key: 'statusName',
    },
    {
      title: 'Токен',
      dataIndex: 'token',
      key: 'token',
    },
    {
      title: 'Хэш-код',
      dataIndex: 'hashCode',
      key: 'hashCode',
    },
    {
      title: 'Сессия',
      dataIndex: 'session',
      key: 'session',
    },
    {
      title: 'Устройство',
      dataIndex: 'device',
      key: 'device',
    },
    {
      title: 'Попытка входа',
      dataIndex: 'countAttempt',
      key: 'countAttempt',
    },
    {
      title: 'Создано',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: 'Обновлено',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'Истекает',
      dataIndex: 'expiration',
      key: 'expiration',
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      render: (record) => (
        <Button
          icon={<DeleteOutlined
          onClick={() => handleDeleteToken(record.userId, record.token)}
        />}/>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowKey="token"
      pagination={false}
      scroll={{x: 'auto'}}
    />
  );
};

export default TokensTableComponent;
