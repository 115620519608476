import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestAccessRolePublicMethodsGet,
  sendRequestAccessRolePublicMethodsCreate,
  clearAccessRolePublicMethodsCreateStatus,
  sendRequestAccessRolePublicMethodsDelete,
  clearAccessRolePublicMethodsDeleteStatus,
} from '../../../../redux/reducers/utils/servicesReducers/servicesReducer';
import { Row, Col, Typography } from 'antd'
import Loader from '../../../../components/Loader';
import AccessSelectRoleComponent from '../../components/AccessSelectRoleComponent';
import AccessListRoleMethod from './components/AccessListRoleMethod';
import ScrolledContainer from '../../../../components/ScrolledContainer';

const { Title } = Typography;

const RoleMethodsPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    accessRolePublicMethods,
    accessRolePublicMethodsGetRequestStatus,
    accessRolePublicMethodsCreateRequestStatus,
    accessRolePublicMethodsUpdateRequestStatus,
    accessRolePublicMethodsDeleteRequestStatus,
  } = useSelector((store) => store.services);
  const dispatch = useDispatch();
  const methods = accessRolePublicMethods.publicMethods;
  const roleMethods = accessRolePublicMethods.roleUsers;
  const roles = accessRolePublicMethods.roles;
  const [selectedRole, setSelectedRole] = useState({id: 1, value: 'super_administrator', label: 'Суперадминистратора'});

  const onSwitchChange = (isChecked, methodId, roleId, roleMethodId) => {
    const createRequestData = {
      token: localStorage.getItem('token'),
      rolePublicMethods: [
        {
          roleId: roleId,
          publicMethodId: methodId,
        }
      ]
    }
    
    const deleteRequestData = {
      token: localStorage.getItem('token'),
      rolePublicMethods: [
        {
          rolePublicMethodId: roleMethodId,
        }
      ]
    }

    if (isChecked) {
      console.log('createRequestData', createRequestData);
      dispatch(sendRequestAccessRolePublicMethodsCreate(createRequestData));
    } else {
      console.log('deleteRequestData', deleteRequestData);
      dispatch(sendRequestAccessRolePublicMethodsDelete(deleteRequestData));
    }
  }

  useEffect(() => {
    if (accessRolePublicMethodsCreateRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessRolePublicMethodsGet(requestData));
      dispatch(clearAccessRolePublicMethodsCreateStatus());
    }
    if (accessRolePublicMethodsDeleteRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessRolePublicMethodsGet(requestData));
      dispatch(clearAccessRolePublicMethodsDeleteStatus());
    }
  }, [accessRolePublicMethodsCreateRequestStatus, accessRolePublicMethodsDeleteRequestStatus])

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestAccessRolePublicMethodsGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  return (
    <ScrolledContainer>
      {(
        accessRolePublicMethodsGetRequestStatus === 'pending' ||
        accessRolePublicMethodsCreateRequestStatus === 'pending' ||
        accessRolePublicMethodsUpdateRequestStatus === 'pending' ||
        accessRolePublicMethodsDeleteRequestStatus === 'pending'
      ) && <Loader />}
      <Title>Управление методами</Title>
      {roles && <AccessSelectRoleComponent
        roles={roles}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
      />}
      {methods && roleMethods && <AccessListRoleMethod
        selectedRole={selectedRole}
        methods={methods}
        roleMethods={roleMethods}
        onSwitchChange={onSwitchChange}
      />}
    </ScrolledContainer>
  );
};

export default RoleMethodsPage;
