import { takeLatest } from 'redux-saga/effects';
import {
  //Notification Logs
  handleGetNotificationLogs,
} from './servicesLogsHandler';
import {
  //Notification Logs
  sendRequestNotificationLogsGet,
} from '../../../../reducers/utils/servicesReducers/servicesLogsReducer';

export function* servicesLogsWatcherSaga() {
  //Notification Logs
  yield takeLatest(sendRequestNotificationLogsGet.type, handleGetNotificationLogs);
}