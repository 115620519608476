import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFile, setCurrentFilesPage } from '../../../../../redux/reducers/utils/servicesReducers/servicesFileManagerReducer';
import { formatDate } from '../../../../../core/utils';
import { Button, Flex, Table, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const { Text } = Typography;

const FileManagerTableComponent = () => {
    const dispatch = useDispatch();
    const {
        files,
        totalFiles,
        currentFilesPage,
        filesTypes,
    } = useSelector((state) => state.servicesFileManager);

    const handleDeleteFile = (id) => {
        dispatch(deleteFile(id));
    }

    const columns = [
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
        },
        {
            title: 'Дата',
            key: 'created',
            render: ((record) => (
                <Flex vertical>
                    <Text>Создан: {formatDate(record.created)}</Text>
                    <Text type='secondary'>Изменен: {formatDate(record.updated)}</Text>
                </Flex>
            )),
        },
        {
            title: 'Кухня',
            key: 'ownerId',
            dataIndex: 'ownerId',
        },
        {
            title: 'path',
            key: 'path',
            render: ((record) => (
                <Flex gap='10px'>
                    <Link to={record.path}>{record.path}</Link>
                    <Text type='secondary'>({record.extension}, {record.size} bite, {record.isOriginal && 'Оригинал'})</Text>
                </Flex>
            )),
        },
        {
            title: 'Хранилище',
            key: 'basket',
            dataIndex: 'basket',
        },
        {
            title: 'typeId',
            key: 'typeId',
            render: ((record) => (
                <Flex gap='10px'>
                    <Text>{record.typeId}</Text>
                    <Text>{filesTypes.find(type => type.id === record.typeId)?.name}</Text>
                </Flex>
            )),
        },
        {
            title: 'userId',
            key: 'userId',
            dataIndex: 'userId',
        },
        {
            title: '',
            key: 'action',
            render: ((record) => (
                <Button icon={<DeleteOutlined />} onClick={() => handleDeleteFile(record.id)}/>
            )),
            fixed: 'right',
        },
    ];

    const handleTableChange = (pagination) => {
        dispatch(setCurrentFilesPage(pagination.current));
    };

  return (
      <Table
        dataSource={files}
        columns={columns}
        size='small'
        pagination={{
          current: currentFilesPage,
          total: totalFiles,
          pageSize: 10,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
  );
};

export default FileManagerTableComponent;
