import apiInstance from '../../../../../api';
import { call, put, select } from 'redux-saga/effects';
import {
  //Address Change Geopoint
	sendRequestForAddressChangeGeopointSuccess,
	sendRequestForAddressChangeGeopointError,

  //Assign Courier To Addresses
	sendRequestForAssignCourierToAddressesSuccess,
	sendRequestForAssignCourierToAddressesError,

  //Update Address Comment
	sendRequestForUpdateAddressCommentSuccess,
	sendRequestForUpdateAddressCommentError,

  //Update Order Comment
	sendRequestForUpdateOrderCommentSuccess,
	sendRequestForUpdateOrderCommentError,

  //Couriers Geo Location
  sendRequestWhereCouriersGetSuccess,
  sendRequestWhereCouriersGetError,
  setCouriersLocation,
  setCourierRoute,

  //Couriers Detail
  sendRequestCouriersDetailGetSuccess,
  sendRequestCouriersDetailGetError,
  setCouriersDetail,
  sendRequestCourierRouteSaveSuccess,
  sendRequestCourierRouteSaveError,
} from '../../../../reducers/utils/deliveryReducers/deliveryMapReducer';
import { selectCommonState } from '../../../../reducers/utils/commonReducer';

//Address Change Geopoint
export function* handleAddressChangeGeopoint(action) {
  const api = '/map/address/changeGeopoint?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestForAddressChangeGeopointSuccess());
    } else {
      yield put(sendRequestForAddressChangeGeopointError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestForAddressChangeGeopointError());
  }
}

//Assign Courier To Addresses
export function* handleAssignCourierToAddresses(action) {
  const api = '/courier/assignToLocations?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestForAssignCourierToAddressesSuccess(data.locationIds[0]));
    } else {
      yield put(sendRequestForAssignCourierToAddressesError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestForAssignCourierToAddressesError());
  }
}

//Update Address Comment
export function* handleUpdateAddressComment(action) {
  const api = '/address/updateComment?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestForUpdateAddressCommentSuccess());
    } else {
      yield put(sendRequestForUpdateAddressCommentError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestForUpdateAddressCommentError());
  }
}

//Update Order Comment
export function* handleUpdateOrderComment(action) {
  const api = '/order/updateComment?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestForUpdateOrderCommentSuccess());
    } else {
      yield put(sendRequestForUpdateOrderCommentError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestForUpdateOrderCommentError());
  }
}

//Couriers Geo Location
export function* handleGetWhereCouriersData(action) {
  const api = '/map/getWhereCouriers?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestWhereCouriersGetSuccess());
      yield put(setCouriersLocation(response.data.data.locationCouriers));
      yield put(setCourierRoute(response.data.data.trackCouriers));
    } else {
      yield put(sendRequestWhereCouriersGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestWhereCouriersGetError());
  }
}

//Couriers Detail
export function* handleGetCouriersDetail(action) {
  const api = '/map/getCourierDetails?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestCouriersDetailGetSuccess());
      yield put(setCouriersDetail(response.data.data.addresses));
    } else {
      yield put(sendRequestCouriersDetailGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestCouriersDetailGetError());
  }
}

export function* handleCourierRouteSave(action) {
  const api = '/map/courierRouteSave?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestCourierRouteSaveSuccess());
    } else {
      yield put(sendRequestCourierRouteSaveError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestCourierRouteSaveError());
  }
}
