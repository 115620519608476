import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Typography, Flex, DatePicker, Select, InputNumber } from 'antd';
import styled from 'styled-components';
import { fetchFiles, setCurrentFilesPage } from '../../../../../redux/reducers/utils/servicesReducers/servicesFileManagerReducer';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { Text } = Typography;
const { Option } = Select;

const FileManagerFiltersComponent = () => {
    const dispatch = useDispatch();
    const {
        currentFilesPage,
        totalFiles,
        filesTypes,
    } = useSelector((state) => state.servicesFileManager);
    const [form] = Form.useForm();

    const getQuery = (page, id, createdTo, createdFrom, ownerId, path, extension, size, isOriginal, basket, typeId, userId) => {
        let filters = '';
        if (id) filters += `id: ${id}, `;
        if (createdTo) filters += `createdTo: "${createdTo}", `;
        if (createdFrom) filters += `createdFrom: "${createdFrom}", `;
        if (ownerId) filters += `ownerId: ${ownerId}, `;
        if (path) filters += `path: "${path}", `;
        if (extension) filters += `extension: "${extension}", `;
        if (size) filters += `size: ${size}, `;
        if (isOriginal) filters += `isOriginal: ${isOriginal}, `;
        if (basket) filters += `basket: "${basket}", `;
        if (typeId) filters += `typeId: ${typeId}, `;
        if (userId) filters += `userId: ${userId}, `;

        return `
            query {
                fileStorageS3(
                    limit: 10,
                    page: ${page},
                    ${filters}
                ) {
                    data {
                        basket
                        created
                        extension
                        id
                        isOriginal
                        ownerId
                        path
                        size
                        sort
                        typeId
                        updated
                        userId
                    }
                    paginator {
                        currentPage
                        lastPage
                        perPage
                        total
                    }
                }
                fileStorageS3Type {
                    id
                    name
                }
            }
        `;
    };

    useEffect(() => {
        const query = getQuery(currentFilesPage);
        dispatch(fetchFiles(query));
    }, [currentFilesPage, dispatch]);

    const handleSearch = (values) => {
        const { id, createdTo, createdFrom, ownerId, path, extension, size, isOriginal, basket, typeId, userId } = values;
        const formattedCreatedTo = createdTo ? dayjs(createdTo).format('YYYY-MM-DD') : null;
        const formattedCreatedFrom = createdFrom ? dayjs(createdFrom).format('YYYY-MM-DD') : null;
    
        const query = getQuery(1, id, formattedCreatedTo, formattedCreatedFrom, ownerId, path, extension, size, isOriginal, basket, typeId, userId);
        dispatch(setCurrentFilesPage(1));
        dispatch(fetchFiles(query));
    };

    const handleReset = () => {
        form.resetFields();
        const query = getQuery(1);
        dispatch(setCurrentFilesPage(1));
        dispatch(fetchFiles(query));
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSearch}>
            <Flex gap={16}>
                <StyledFormItem name='id' label='ID'>
                    <InputNumber
                        placeholder='Введите ID'
                        min={0}
                        style={{ width: 150 }}
                    />
                </StyledFormItem>

                <StyledFormItem name='createdTo' label='от' style={{flex: 1}}>
                    <DatePicker
                        placeholder='Выберите дату'
                        format={'DD-MM-YYYY'}
                        style={{ width: '100%' }}
                    />
                </StyledFormItem>

                <StyledFormItem name='createdFrom' label='до' style={{flex: 1}}>
                    <DatePicker
                        placeholder='Выберите дату'
                        format={'DD-MM-YYYY'}
                        style={{ width: '100%' }}
                    />
                </StyledFormItem>

                <StyledFormItem name='ownerId' label='Кухня'>
                    <InputNumber
                        placeholder='Введите ID кухни'
                        min={0}
                        style={{ width: 150 }}
                    />
                </StyledFormItem>

                <StyledFormItem name='userId' label='ID пользователя'>
                    <InputNumber
                        placeholder='Введите ID пользователя'
                        min={0}
                        style={{ width: 150 }}
                    />
                </StyledFormItem>
            </Flex>
            <Flex gap={16}>
                <StyledFormItem name='path' label='Путь файла' style={{flex: 1}}>
                    <Input
                        placeholder='Введите путь'
                        style={{ width: '100%' }}
                    />
                </StyledFormItem>

                <StyledFormItem name='extension' label='Расширение'>
                    <Select
                        placeholder='Выберите расширение'
                        style={{ width: 150 }}
                    >
                        <Option value="pdf">PDF</Option>
                        <Option value="doc">DOC</Option>
                        <Option value="docx">DOCX</Option>
                        <Option value="xls">XLS</Option>
                        <Option value="xlsx">XLSX</Option>
                        <Option value="ppt">PPT</Option>
                        <Option value="pptx">PPTX</Option>
                        <Option value="txt">TXT</Option>
                        <Option value="jpg">JPG</Option>
                        <Option value="png">PNG</Option>
                        <Option value="gif">GIF</Option>
                    </Select>
                </StyledFormItem>

                <StyledFormItem name='size' label='Размер, байт'>
                    <InputNumber
                        placeholder='Введите размер'
                        min={0}
                        style={{ width: 150 }}
                    />
                </StyledFormItem>

                <StyledFormItem name='isOriginal' label='Оригинал'>
                    <Select
                        placeholder='Да/Нет'
                        style={{ width: 150 }}
                    >
                        <Option value="trye">Да</Option>
                        <Option value="false">Нет</Option>
                    </Select>
                </StyledFormItem>

                <StyledFormItem name='basket' label='Хранилище'>
                    <Input
                        placeholder='Укажите хранилище'
                        style={{ width: 150 }}
                    />
                </StyledFormItem>

                <StyledFormItem name='typeId' label='Тип'>
                    <Select
                        placeholder='Да/Нет'
                        style={{ width: 150 }}
                    >
                        {filesTypes.map((type) => (
                            <Option key={type.id} value={type.id}>{type.name}</Option>
                        ))}
                    </Select>
                </StyledFormItem>
            </Flex>
            <Flex style={{marginTop: '20px'}} justify='space-between' gap={16}>
                <Text type='secondary'>Найдено файлов: {new Intl.NumberFormat().format(totalFiles)}</Text>
                <Flex gap={16}>
                    <Button type='primary' htmlType='submit'>Поиск</Button>
                    <Button htmlType='reset' onClick={handleReset}>Сбросить</Button>
                </Flex>
            </Flex>
        </Form>
    );
};

export default FileManagerFiltersComponent;

const StyledFormItem = styled(Form.Item)`
    margin: 5px
`