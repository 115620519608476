import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../../../redux/reducers/utils/clientsReducers/clientsSearchReducer';
import { Flex, Table, Checkbox, Typography, Tag, Button } from 'antd';
import { formatDate } from '../../../../../core/utils';
import PrintTable from '../../../../../components/PrintTable';

const { Title, Text } = Typography;

const ClientsTableComponent = () => {
  const { clients, totalClients } = useSelector((store) => store.clientsSearch);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 0,
  });
  const [selectedClients, setSelectedClients] = useState([]);

  const clientsWithKey = clients.map(item => ({...item, key: item.id}))

  const columns = [
    {
      title: 'ID',
      key: 'userId',
      render: ((record) => (
        <Text copyable>{record.userId}</Text>
      )),
    },
    {
      title: 'Состояние',
      key: 'stateName',
      render: ((record) => (
        <Tag>{record.stateName}</Tag>
      )),
    },
    {
      title: 'Клиенты',
      key: 'name',
      render: ((record) => (
        <div>
          <div>{record.userPhone}</div>
          <div>{record.userName}</div>
        </div>
      )),
    },
    {
      title: 'Адрес',
      key: 'address',
      render: ((record) => (
        <>
          {record.addressActive !== null ?
            <div>
              <div>
                {record.addressActive.addressId && <Text>ID: {record.addressActive.addressId}</Text>} 
                {record.addressActive.citiName && <Text>г.{record.addressActive.citiName}, </Text>} 
                {record.addressActive.street && <Text>ул.{record.addressActive.street}, </Text>} 
                {record.addressActive.house && <Text>д.{record.addressActive.house}, </Text>} 
                {record.addressActive.flat && <Text>кв.{record.addressActive.flat}, </Text>} 
                {record.addressActive.floor && <Text>э.{record.addressActive.floor}, </Text>} 
                {record.addressActive.entrance && <Text>вх.{record.addressActive.entrance}, </Text>} 
                {record.addressActive.structure && <Text>корп.{record.addressActive.structure}, </Text>} 
              </div>
              <div>Количество адресов: {record.countAddress}</div>
            </div>
          :
            <div>Нет адреса</div>
          }
        </>
      )),
      onCell: (record) => ({
        style: {cursor: 'pointer'},
        onClick: () => handleCellClick(record),
      })
    },
    {
      title: 'Кол-во доставленных заказов',
      dataIndex: 'countOrdersDelivered',
      key: 'countOrdersDelivered',
      onCell: (record) => ({
        style: {cursor: 'pointer'},
        onClick: () => handleCellClick(record),
      })
    },
    {
      title: 'Cумма, дата, кухня и статус последнего заказа',
      key: 'orders',
      render: ((record) => {
        let color = '';
        if (record.lastOrder !== null && record.lastOrder.orderStatus === 10) {
          color = '#0059ff';
        } else if (record.lastOrder !== null && record.lastOrder.orderStatus === 20) {
          color = '#00b7ff';
        } else if (record.lastOrder !== null && record.lastOrder.orderStatus === 30) {
          color = '#00ff9d';
        } else if (record.lastOrder !== null && record.lastOrder.orderStatus === 40) {
          color = '#0bdb04';
        } else if (record.lastOrder !== null && record.lastOrder.orderStatus === 50) {
          color = '#00af1d'
        } else {
          color = '#ff0000';
        }

        return (
          <>
            {record.lastOrder !== null ?
              <Flex gap='large' justify='space-around'>
                <Flex vertical>
                  <Text style={{fontSize: '10px'}} type='secondary'>{formatDate(record.lastOrder.created)}</Text>
                  <Text>{record.lastOrder.kitchenName}</Text>
                </Flex>
                <Flex vertical>
                  <Text strong>{record.lastOrder.orderPrice}р.</Text>
                  <Tag style={{width: 'fit-content'}} color={color}>{record.lastOrder.orderStatusName}</Tag>
                </Flex>
              </Flex>
            :
              <div>-</div>
            }
          </>
        )
      }),
      onCell: (record) => ({
        style: {cursor: 'pointer'},
        onClick: () => handleCellClick(record),
      })
    },
    {
      title: '',
      key: 'actions',
      render: ((record) => (
        <ActionsCell record={record} selectedClients={selectedClients} setSelectedClients={setSelectedClients}/>
      ))
    },
  ];

  useEffect(() => {
    setPagination({...pagination, total: totalClients})
  }, [totalClients]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    dispatch(setCurrentPage(pagination.current))
  };

  const handleCellClick = (record) => {
    console.log(record)
    // navigate(record.userPhone);
    window.open(`search/${record.userPhone}`, '_blank');
  };

  const handleRemoveSelectedClient = (clientToRemove) => {
    setSelectedClients(prevState => prevState.filter(client => client.userId !== clientToRemove.userId));
  };

  const handleSendPushNotification = () => {
    alert('Какая-то функция');
  };

  const visibleClients = selectedClients.slice(0, 4);
  const hiddenClientsCount = selectedClients.length - visibleClients.length;

  return (
    <>
      {selectedClients.length > 0 && (
        <div style={{ marginBottom: '20px' }}>
          <div style={{ marginBottom: '10px' }}>
            <Title level={5}>Выбранные пользователи:</Title>
            {visibleClients.map(user => (
              <Tag
                key={user.userId}
                closable
                onClose={() => handleRemoveSelectedClient(user)}
              >
                {user.userPhone}
              </Tag>
            ))}
            {hiddenClientsCount > 0 && (
              <Tag>{`...еще ${hiddenClientsCount}`}</Tag>
            )}
          </div>
          <Button style={{ width: 'fit-content' }} type='primary' onClick={handleSendPushNotification}>Отправить ПУШ-уведомление</Button>
        </div>
      )}
      <PrintTable>
        <Table
          dataSource={clientsWithKey}
          columns={columns}
          bordered
          size="small"
          pagination={{
            ...pagination,
            showSizeChanger: false,
            showQuickJumper: true,
          }}
          onChange={handleTableChange}
        />
      </PrintTable>
    </>
  );
};

export default ClientsTableComponent;

const ActionsCell = ({record, selectedClients, setSelectedClients}) => {
  const isChecked = selectedClients.some(client => client.userId === record.userId);

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedClients(prevState => [...prevState, record]);
    } else {
      setSelectedClients(prevState => prevState.filter(client => client.userId !== record.userId));
    }
  };

  return (
    <div>
      <Checkbox checked={isChecked} size='large' onChange={handleCheckboxChange}/>
    </div>
  );
}