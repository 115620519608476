import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

const SuperTimeComponent = ({ style, className, serverTime, cityTimezone = 4, userTimezone = 4, format = "HH:mm" }) => {
    const adjustedCityTime = moment(serverTime).add(cityTimezone, 'hours').format(format);
    const adjustedUserTime = moment(serverTime).add(userTimezone, 'hours').format(format);

    const formattedUserTimezone = userTimezone > 0 ? `+${userTimezone}` : userTimezone;

    return (
        <Wrapper style={style} className={className}>
            <span>{adjustedCityTime}</span>
            {cityTimezone !== userTimezone && (
                <p>{adjustedUserTime} (UTC{formattedUserTimezone})</p>
            )}
        </Wrapper>
    );
};

export default SuperTimeComponent;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    span {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
    }
    p {
        font-size: 12px;
        color: #b8b8b8;
    }
`;
