import React, { useEffect, useState } from 'react';
import { Modal, Input, Button, Typography, message } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setIsClaimCreationModalOpen, setSelectedGroupOrder } from '../../../../../../redux/reducers/utils/financeReducers/financeOrdersReducer';
import ImgUploader from '../../../../../../components/ImgUploader';

const { TextArea } = Input;
const { Title, Text } = Typography;

const ClaimCreationModal = () => {
  const dispatch = useDispatch();
  const {
    isClaimCreationModalOpen,
    selectedGroupOrder,
  } = useSelector((state) => state.financeOrders);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [returnQuantities, setReturnQuantities] = useState({});
  const [formFields, setFormFields] = useState({
    points: '',
    card: '',
    reason: '',
    photos: [],
  });

  useEffect(() => {
    if (selectedGroupOrder) {
      setSelectedOrder(selectedGroupOrder);
      setReturnQuantities(
        selectedGroupOrder.orders.reduce((acc, order) => {
          order.orderProduct.forEach((product) => {
            acc[product.id] = 0;
          });
          return acc;
        }, {})
      );
    }
  }, [selectedGroupOrder]);

  const handleModalClose = () => {
    dispatch(setIsClaimCreationModalOpen(false));
    dispatch(setSelectedGroupOrder(null));
    setFormFields({
      reason: '',
      photos: [],
    })
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhotoUpload = ( file ) => {
    setFormFields((prev) => ({
      ...prev,
      photos: file,
    }));
  };

  const handleSubmit = () => {
    console.log('Возврат оформлен', {
      orderId: selectedOrder.id,
      returnQuantities,
      ...formFields,
    });
    message.success('Возврат успешно оформлен');
    handleModalClose();
  };

  return (
    <Modal
      title="Оформление претензии"
      open={isClaimCreationModalOpen}
      onCancel={handleModalClose}
      footer={[
        <Button key="cancel" onClick={handleModalClose}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Оформить претензию
        </Button>,
      ]}
    >
      <FormSection>
        <Title level={5}>Претензия</Title>
        <TextArea
          placeholder="Введите описание претензии для кухни"
          rows={3}
          name="reason"
          value={formFields.reason}
          onChange={handleInputChange}
        />
        <ImgUploader
          multiple
          fileList={formFields.photos}
          onUpload={handlePhotoUpload}
        />
      </FormSection>
    </Modal>
  );
};

export default ClaimCreationModal;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
`
