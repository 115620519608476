import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  globalLoader: true,
  serverTime: null,
  isMobile: false,
  orientation: 'portrait',
  apiUrl: 'https://api-test.menuforme.online/api/v1/admin',
  graphUrl: 'https://api-test.menuforme.online/api/graphql/5496880-8345687-2394984-221781/admin',
};

export const selectCommonState = state => state.common;

const commonSlice = createSlice({
  name: 'common',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    setGlobalLoader: (state, action) => {
      state.globalLoader = action.payload;
    },
    setServerTime: (state, action) => {
      state.serverTime = action.payload;
    },
    updateServerTime: (state, action) => {
      state.serverTime = action.payload;
    },
    setDevice: (state, action) => {
      state.isMobile = action.payload;
    },
    setOrientation: (state, action) => {
      state.orientation = action.payload;
    },
    setApiUrl: (state, action) => {
      state.apiUrl = action.payload;
    },
    setGraphUrl: (state, action) => {
      state.graphUrl = action.payload;
    },
  },
});

export const {
  setGlobalLoader,
  setServerTime,
  updateServerTime,
  setDevice,
  setOrientation,
  setApiUrl,
  setGraphUrl,
} = commonSlice.actions;

export default commonSlice.reducer;
