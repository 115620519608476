import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import { fetchGraphQL } from '../../../../api';
import { message } from 'antd';

export const fetchFiles = createAsyncThunk(
    'servicesFileManager/getFiles',
    async (query,{ rejectWithValue }) => {
        try {
            const response = await fetchGraphQL(query);
    
            if (response.data.fileStorageS3) {
                return {
                    files: response.data.fileStorageS3.data,
                    totalFiles: response.data.fileStorageS3.paginator.total,
                    filesTypes: response.data.fileStorageS3Type,
                }
            } else {
                return rejectWithValue('Failed to fetch data');
            }
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
            return rejectWithValue(error);
        }
    }
);

export const deleteFile = createAsyncThunk(
    'servicesFileManager/deleteFile',
    async (id,{ rejectWithValue }) => {
        const mutation = `
            mutation {
                deleteFileStorageS3(id: ${id}) {
                    message
                }
            }
        `;

        try {
            const response = await fetchGraphQL(mutation);
    
            if (response.errors) {
                return rejectWithValue(response.errors.map(error => error.message).join(', '));
            }

            if (response.data.deleteFileStorageS3) {
                return {
                    message: response.data.deleteFileStorageS3.message,
                    id: id,
                }
            } else {
                return rejectWithValue('Failed to fetch data');
            }
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
            return rejectWithValue(error);
        }
    }
);

const initialState = {
    filesGetRequestStatus: null,
    deleteFileRequestStatus: null,
    files: [],
    totalFiles: 0,
    currentFilesPage: 1,
    filesTypes: [],
};

const servicesFileManagerSlice = createSlice({
    name: 'servicesFileManager',
    initialState,
    extraReducers: (builder) => {
        builder
        // getFiles
        .addCase(fetchFiles.pending, (state) => { state.filesGetRequestStatus = 'pending'; })
        .addCase(fetchFiles.fulfilled, (state, action) => {
            state.filesGetRequestStatus = 'fulfilled';
            state.files = action.payload.files;
            state.totalFiles = action.payload.totalFiles;
            state.filesTypes = action.payload.filesTypes;
        })
        .addCase(fetchFiles.rejected, (state) => { state.filesGetRequestStatus = 'rejected'; })

        // deleteFile
        .addCase(deleteFile.pending, (state) => { state.deleteFileRequestStatus = 'pending'; })
        .addCase(deleteFile.fulfilled, (state, action) => {
            state.deleteFileRequestStatus = 'fulfilled';
            state.files = state.files.filter(file => file.id !== action.payload.id);
            message.success(action.payload.message);
        })
        .addCase(deleteFile.rejected, (state) => { state.deleteFileRequestStatus = 'rejected'; })

        // PURGE
        .addCase(PURGE, () => { return initialState; })
    },
    reducers: {
        setCurrentFilesPage: (state, action) => {
            state.currentFilesPage = action.payload;
        },
    },
});

export const {
    setCurrentFilesPage,
} = servicesFileManagerSlice.actions;

export default servicesFileManagerSlice.reducer;
