import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import apiInstance, { fetchGraphQL } from '../../../../api';
import { selectCommonState } from '../commonReducer';
import axios from 'axios';
import { message } from 'antd';

export const fetchOrders = createAsyncThunk(
  'finance/fetchOrders',
  async (query, { rejectWithValue }) => {
    try {
      const response = await fetchGraphQL(query);

      if (response.data.orderGroups) {
        return {
          orderGroups: response.data.orderGroups,
          orderStatuses: response.data.orderStatuses,
        }
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error);
    }
  }
);

export const updateOrderStatus = createAsyncThunk(
  'finance/updateOrderStatus',
  async ({ orderId, newStatusId }, { rejectWithValue }) => {
    const mutation = `
      mutation {
        updateOrder(id: ${orderId}, status: "${newStatusId}") {
          order {
            id
            orderStatus {
              id
              name
            }
          }
          message
        }
      }
    `;

    try {
      const response = await fetchGraphQL(mutation);

      if (response.errors) {
        return rejectWithValue(response.errors.map(error => error.message).join(', '));
      }

      if (response.data.updateOrder) {
        return response.data.updateOrder;
      } else {
        return rejectWithValue('Failed to update order status');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateOrderComment = createAsyncThunk(
  'finance/updateOrderComment',
  async ({ orderId, newComment }, { rejectWithValue }) => {
    const mutation = `
      mutation {
        updateOrder(id: ${orderId}, comment: "${newComment}") {
          order {
            id
            comment
          }
          message
        }
      }
    `;

    try {
      const response = await fetchGraphQL(mutation);

      if (response.errors) {
        return rejectWithValue(response.errors.map(error => error.message).join(', '));
      }

      if (response.data.updateOrder) {
        return response.data.updateOrder;
      } else {
        return rejectWithValue('Failed to update order status');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const refundOrder = createAsyncThunk(
  'finance/refundOrder',
  async ({ orderGroupId, items, points, card }, { rejectWithValue }) => {
    const mutation = `
      mutation {
        refundOrder(
          orderGroupId: ${orderGroupId},
          items: [${items.map(item => `{orderProductId: ${item.orderProductId}, amount: ${item.amount}}`).join(', ')}],
          points: ${points},
          card: ${card}
        ) {
          message
        }
      }
    `;

    try {
      const response = await fetchGraphQL(mutation);

      if (response.errors) {
        return rejectWithValue(response.errors.map(error => error.message).join(', '));
      }

      if (response.data.message) {
        return response.data.message;
      } else {
        return rejectWithValue('Failed to update order status');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
    fetchOrdersStatus: null,
    updateOrdersStatus: null,
    orders: [],
    totalOrders: 0,
    currentOrdersPage: 1,
    orderStatusesList: [],
    serverTime: null,
    isProductsReturnModalOpen: false,
    isLogsModalOpen: false,
    isClaimCreationModalOpen: false,
	  selectedGroupOrder: null,
    selectedOrder: null,
};

const financeOrdersSlice = createSlice({
  name: 'financeOrders',
  initialState,
  extraReducers: (builder) => {
    builder
      // fetchOrders
      .addCase(fetchOrders.pending, (state) => { state.fetchOrdersStatus = 'pending'; })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        const data = action.payload;

        state.fetchOrdersStatus = 'fulfilled';
        state.orders = data?.orderGroups?.data;
        state.totalOrders = data?.orderGroups?.paginator?.total;
        state.orderStatusesList = data?.orderStatuses;
      })
      .addCase(fetchOrders.rejected, (state) => { state.fetchOrdersStatus = 'rejected'; })

      // updateOrderStatus
      .addCase(updateOrderStatus.pending, (state) => { state.updateOrdersStatus = 'pending'; })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        state.updateOrdersStatus = 'fulfilled';

        const data = action.payload;
        state.orders.forEach((groupOrder) => {
          const order = groupOrder.orders.find((order) => {
            return order.id === data?.order?.id
          });

          if (order) {
            order.orderStatus.id = data?.order?.orderStatus?.id;
            order.orderStatus.name = data?.order?.orderStatus?.name;
            message.success(data?.message);
          }
        });
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.updateOrdersStatus = 'rejected';
        message.error(action.payload);
      })

      // updateOrderComment
      .addCase(updateOrderComment.pending, (state) => { state.updateOrdersStatus = 'pending'; })
      .addCase(updateOrderComment.fulfilled, (state, action) => {
        state.updateOrdersStatus = 'fulfilled';

        const data = action.payload;
        state.orders.forEach((groupOrder) => {
          const order = groupOrder.orders.find((order) => {
            return order.id === data?.order?.id
          });

          if (order) {
            order.comment = data?.order?.comment;
            message.success(data?.message);
          }
        });
      })
      .addCase(updateOrderComment.rejected, (state, action) => {
        state.updateOrdersStatus = 'rejected';
        message.error(action.payload);
      })

      // PURGE
      .addCase(PURGE, () => { return initialState; })
  },
  reducers: {
    setCurrentOrdersPage: (state, action) => {
      state.currentOrdersPage = action.payload;
    },
    setIsProductsReturnModalOpen: (state, action) => {
      state.isProductsReturnModalOpen = action.payload;
    },
    setIsLogsModalOpen: (state, action) => {
      state.isLogsModalOpen = action.payload;
    },
    setIsClaimCreationModalOpen: (state, action) => {
      state.isClaimCreationModalOpen = action.payload;
    },
    setSelectedGroupOrder: (state, action) => {
      state.selectedGroupOrder = action.payload;
    },
    setSelectedOrder: (state, action) => {
      state.selectedOrder = action.payload;
    },
  },
});

export const {
    setCurrentOrdersPage,
    setIsProductsReturnModalOpen,
    setIsLogsModalOpen,
    setIsClaimCreationModalOpen,
    setSelectedGroupOrder,
    setSelectedOrder,
} = financeOrdersSlice.actions;

export default financeOrdersSlice.reducer;
