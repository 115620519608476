import React, { useState } from 'react';
import { Input, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const EditableComment = ({ orderId, initialComment, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [comment, setComment] = useState(initialComment);

  const handleEdit = () => setIsEditing(true);

  const handleSave = () => {
    setIsEditing(false);
    onSave(orderId, comment);
  };

  const handleInputChange = (e) => {
    setComment(e.target.value);
  };

  const handleBlur = () => {
    if (isEditing) {
      handleSave();
    }
  };

  return (
    <CommentWrapper>
        <Typography.Text type='secondary' style={{fontSize: '12px'}}>Комментарий</Typography.Text>
      {isEditing ? (
        <Input.TextArea
            style={{width: '100%'}}
            placeholder='Комментарий'
            value={comment}
            rows={2}
            onChange={handleInputChange}
            onPressEnter={handleSave}
            onBlur={handleBlur}
            autoFocus
        />
      ) : (
        <CommentText onClick={handleEdit}>
          {comment || ''}
          <EditOutlined/>
        </CommentText>
      )}
    </CommentWrapper>
  );
};

export default EditableComment;

const CommentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 250px;
`;

const CommentText = styled(Typography.Text)`
    cursor: pointer;
    color: #2a2a2a;
    transition: all .3s;
    &:hover {
        color: #0080ff;
        text-decoration: underline;
    }
`;
