import React from 'react';
import { Container, Page, ScrolledCol, VerticalSpace } from '../../../../core/styles';
import ProductsReturnModal from './components/productsReturnModal/ProductsReturnModal';
import GroupOrdersTable from './components/groupOrdersTable/GroupOrdersTable';
import FiltersComponent from './components/filtersComponent/FiltersComponent';
import { Divider } from 'antd';
import ClaimCreationModal from './components/claimCreationModal/ClaimCreationModal';
import LogsModal from './components/logsModal/LogsModal';

const OrdersPage = () => {
	return (
		<Page>
			<ScrolledCol>
				<Container>
					<VerticalSpace>

						<FiltersComponent/>

						<Divider/>

						<GroupOrdersTable/>

						<ProductsReturnModal/>
						<LogsModal/>
						<ClaimCreationModal/>

					</VerticalSpace>
				</Container>
			</ScrolledCol>
		</Page>
	);
};

export default OrdersPage;
