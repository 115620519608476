import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestAccessPublicMethodsGet,
  sendRequestAccessPublicMethodsCreate,
  clearAccessPublicMethodsCreateStatus,
  sendRequestAccessPublicMethodsUpdate,
  clearAccessPublicMethodsUpdateStatus,
  sendRequestAccessPublicMethodsDelete,
  clearAccessPublicMethodsDeleteStatus,
} from '../../../../redux/reducers/utils/servicesReducers/servicesReducer';
import { Row, Col, Typography } from 'antd'
import Loader from '../../../../components/Loader';
import ModalCreateAccessMethod from './components/ModalCreateAccessMethod';
import AccessListMethod from './components/AccessListMethod';
import ScrolledContainer from '../../../../components/ScrolledContainer';

const { Title } = Typography;

const MethodsDirectoryPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    accessPublicMethods,
    accessPublicMethodsGetRequestStatus,
    accessPublicMethodsCreateRequestStatus,
    accessPublicMethodsUpdateRequestStatus,
    accessPublicMethodsDeleteRequestStatus,
  } = useSelector((store) => store.services);
  const dispatch = useDispatch();

  const onCreateMethod = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      publicMethods: [
        {
          name: values.name,
          description: values.description,
          keyPublicMethod: values.key,
        }
      ]
    };

    dispatch(sendRequestAccessPublicMethodsCreate(requestData));
  };

  useEffect(() => {
    if (accessPublicMethodsCreateRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessPublicMethodsGet(requestData));
      dispatch(clearAccessPublicMethodsCreateStatus());
    }
  }, [accessPublicMethodsCreateRequestStatus])

  const onUpdateMethod = (name, description, keyPublicMethod, publicMethodId) => {
    const requestData = {
      token: localStorage.getItem('token'),
      publicMethods: [
        {
          name: name,
          description: description,
          keyPublicMethod: keyPublicMethod,
          publicMethodId: publicMethodId,
        }
      ]
    };
    console.log(requestData)
    dispatch(sendRequestAccessPublicMethodsUpdate(requestData));
  };

  useEffect(() => {
    if (accessPublicMethodsUpdateRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessPublicMethodsGet(requestData));
      dispatch(clearAccessPublicMethodsUpdateStatus());
    }
  }, [accessPublicMethodsUpdateRequestStatus])

  const onDeleteMethod = (id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      publicMethods: [
        {
          publicMethodId: id,
        }
      ]
    };
    
    dispatch(sendRequestAccessPublicMethodsDelete(requestData));
  };

  useEffect(() => {
    if (accessPublicMethodsDeleteRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessPublicMethodsGet(requestData));
      dispatch(clearAccessPublicMethodsDeleteStatus());
    }
  }, [accessPublicMethodsDeleteRequestStatus])

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestAccessPublicMethodsGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  return (
    <ScrolledContainer>
      {(
        accessPublicMethodsGetRequestStatus === 'pending' ||
        accessPublicMethodsCreateRequestStatus === 'pending' ||
        accessPublicMethodsUpdateRequestStatus === 'pending' ||
        accessPublicMethodsDeleteRequestStatus === 'pending'
      ) && <Loader />}
      <Title>Справочник методов админ-панели</Title>
      <ModalCreateAccessMethod onCreateMethod={onCreateMethod}/>
      <AccessListMethod
        accessPublicMethods={accessPublicMethods}
        onUpdateMethod={onUpdateMethod}
        onDeleteMethod={onDeleteMethod}
      />
    </ScrolledContainer>
  );
};

export default MethodsDirectoryPage;
