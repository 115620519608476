import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const NetworkStatusLamp = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <LampWrapper isOnline={isOnline} title={isOnline ? 'Вы онлайн' : 'Вы офлайн'}/>
  );
};

export default NetworkStatusLamp;

const LampWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: ${(props) => (props.isOnline ? '#41bb00' : '#ff0000')};
  box-shadow: 0 0 10px 1px ${(props) => (props.isOnline ? '#41bb00' : '#ff0000')};
  transition: background-color 0.3s, box-shadow 0.3s;
`
