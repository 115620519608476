import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setKitchensShow,
    setMapAddressLocation,
    setCurrentKitchen,
    updateMapDataVisibility,
    fetchAddCourierToKitchen,
    fetchDeleteCourierToKitchen,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import { Button, Flex, Typography, Empty, Divider, Row, Col, List, Badge } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ReactComponent as IconPlacemarkDelivered } from '../../../../../assets/icons/icon-order-status-complited.svg';

const { Text, Title } = Typography;

const KitchensComponent = () => {
    const {
        kitchensShow,
        kitchens,
        currentKitchen,
        selectedFilters,
        selectedCourierToAppoint,
    } = useSelector((store) => store.deliveryMap);
    const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(setKitchensShow(true));
    dispatch(updateMapDataVisibility({kitchens: true}));
  };

  const handleClose = () => {
    dispatch(setKitchensShow(false));
    dispatch(setCurrentKitchen(null));
    dispatch(updateMapDataVisibility({kitchens: false}));
  };

  const handleKitchenClick = (kitchen) => {
    if (selectedCourierToAppoint !== null) {
      dispatch(fetchAddCourierToKitchen({
        token: localStorage.getItem('token'),
        deliveryDay: selectedFilters.date,
        raceId: selectedFilters.timePeriod,
        courierId: selectedCourierToAppoint.courierId,
        kitchenId: kitchen.kitchenId,
      }));
    } else if (kitchen.longitude && kitchen.latitude) {
        dispatch(setMapAddressLocation([kitchen.latitude, kitchen.longitude]));
    } else {
      return;
    }
  }

  const handleCourierDelete = (id) => {
    dispatch(fetchDeleteCourierToKitchen({token: localStorage.getItem('token'), kitchenCourierAssignmentId: id}));
  }

//   {
//     "kitchenId": 152005,
//     "kitchenName": "Горошина",
//     "longitude": 53.5303,
//     "latitude": 49.3461,
//     "timezone": 4,
//     "cityId": 18,
//     "racePolygonId": 6,
//     "polygons": [
//         {
//             "polygonName": "Самара",
//             "timeTransferringOrderToCourier": "07:30",
//             "orderExpDate": "06:00"
//         },
//         {
//             "polygonName": "Самара",
//             "timeTransferringOrderToCourier": "07:30",
//             "orderExpDate": "06:00"
//         }
//     ],
//     "kitchenCourierAssignment": [
// {
      //   "id": 6,
      //   "deliveryDay": "2024-06-12",
      //   "raceId": 1,
      //   "courierId": 1339136,
      //   "kitchenId": 152005,
      //   "created": "2024-12-15 14:38:55"
      // }
      // ],
//     "menu": [],
//     "countOrders": 0,
//     "delivered_orders": 0,
//     "canceled_orders": 0,
//     "undelivered_orders": 0,
//     "numberOrdersCollected": 0
// }

  return (
    <>
      {!kitchensShow &&
        <Button
          style={{marginTop: '10px', marginLeft: '10px', marginRight: '10px'}}
          type="primary"
          onClick={handleOpen}
        >
          <MenuOutlined/> K
        </Button>
      }
      {kitchensShow &&
        <Wrapper>
          <Header>
            <Flex>
              <Flex align='center' gap='12px'>
                <Title level={5} style={{margin: 0}}>Кухни</Title>
              </Flex>
              <Button
                style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 800 }}
                type='text'
                icon={<CloseOutlined/>}
                onClick={handleClose}
              />
            </Flex>
          </Header>

          <Content>
            {kitchens.length > 0 ? 
                kitchens.map((kitchen) => (
                    <KitchenCard
                      key={kitchen.kitchenId}
                      active={!!currentKitchen && currentKitchen.kitchenId === kitchen.kitchenId}
                      kitchen={kitchen}
                      onClick={handleKitchenClick}
                      onDelete={handleCourierDelete}
                    />
                ))
            :
              <Empty description="Нет данных" />
            }
          </Content>
        </Wrapper>
      }
    </>
  );
};

export default KitchensComponent;

const KitchenCard = ({ active, kitchen, onClick, onDelete}) => {
  return (
    <Card
        selected={active}
    >
      <div onClick={() => onClick(kitchen)}>
        <Flex align='center' gap='8px'>
          <Text style={{fontSize: '12px'}} secondary>ID: {kitchen.kitchenId || '---'}</Text>
          <Title style={{ margin: '0' }} level={5}>{kitchen.kitchenName || 'Нет названия'}</Title>
        </Flex>
        <Text style={{fontSize: '12px', color: kitchen.menu.length > 0 && kitchen.menu[0].status === 'publish' ? '#21930f' : '#c41313'}}>
          {kitchen.menu.length > 0 && kitchen.menu[0].status === 'publish' ? 'Меню опубликовано!' : 'Меню не опубликовано!'}
        </Text>
        {/* <Flex gap='8px'>
          <div>Всего: {kitchen.countOrders}</div>
          <div>Д: {kitchen.delivered_orders}</div>
          <div>З: {kitchen.canceled_orders}</div>
          <div>Н: {kitchen.undelivered_orders}</div>
          <div>С: {kitchen.numberOrdersCollected}</div>
        </Flex> */}
      </div>

      {kitchen.kitchenCourierAssignment.length > 0 && (
        <>
          <List
            dataSource={kitchen.kitchenCourierAssignment}
            renderItem={(courier) => (
              <List.Item style={{ padding: "4px 0", width: '100%' }}>
                <StyledStatus>
                  <StyledCourier style={{ borderColor: courier.courierColor }}>{courier.courierName}</StyledCourier>
                  <IconPlacemarkDelivered style={{ fill: courier.courierColor }} />
                  <StyledDeleteButton onClick={() => onDelete(courier.id)}>
                    <CloseOutlined />
                  </StyledDeleteButton>
                </StyledStatus>
              </List.Item>
            )}
          />
        </>
      )}
    </Card>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 280px;
  height: calc(100vh - 45px);
  background: #ffffff;
  border: 1px solid #f1f1f1;
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 100%;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
  }
`
const Card = styled.div`
  border: 1px solid #f1f1f1;
  padding: 10px;
  background: ${({ selected }) => (selected ? '#f1f1f1' : '#ffffff')};
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
`
const StyledStatus = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledCourier = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  width: auto;
  height: 20px;
  font-size: 13px;
  border: 1px solid;
  border-radius: 20px;
`
const StyledDeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff4d4f;

  &:hover {
    color: #d32f2f;
  }
`
